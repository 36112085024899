import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal, Form, Button} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEye, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope, faPaperPlane} from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link } from "react-router-dom";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'






function MyVerticallyCenteredModal(props) {


    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [phoneivalue, setPhoneiValue] = useState();

    const [sendBtnTxt, setSendBtnTxt] = useState(" SEND ");
    const [inqSent, setInqSent] = useState(false);
  
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            inq: '',
            itemdesc: `(Lot No:${props.theitem}) ${props.theitemsno} - ${props.theitemdesc}`,
            itemnum: props.theitem
        },
          
        validationSchema: Yup.object({
            firstName: Yup.string()
              //.max(25, 'Must be 25 characters or less')
              .required('Required'),
            lastName: Yup.string()
              //.max(25, 'Must be 25 characters or less')
              .required('Required'),
            phone: Yup.string()
              //.max(25, 'Must be 25 characters or less')
              .required('Required'),
            email: Yup.string()
              //.email('Invalid email address')
              .required('Required'),
            inq: Yup.string().required('Required')
        }),

        onSubmit: values => {
    
            console.log("the form content"+JSON.stringify(values, null, 2))
       
            
            fetch('https://bidlive.bid/rest/proja/api/sendinq2', {
               method: 'post',
               headers: {'Content-Type': 'application/json'},
               body: JSON.stringify(values, null, 2)
       
             }).then(res=> res.json())
               .then(res=> {setInqSent(false); setSendBtnTxt(" SEND ")}); 
            

            props.onHide();
            props.onNext();
   
       
        },  
    });
  
    function closeThisModal() {
      
  
    }
    const togglePasswordVisiblity = () => {
      console.log('toggle eye')
      setPasswordShown1(passwordShown1 ? false : true);

    };
    const togglePasswordVisiblity2 = () => {
        console.log('toggle eye')
        setPasswordShown2(passwordShown2 ? false : true);

    };
  
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton  style={{backgroundColor: '#e9ecef'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Item Inquiry &nbsp;<span style={{fontSize: '14px'}}>Please type in your inquiry and we will get back to you the soonest. Thank you.</span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Container>
          <Row>
            <Col sm={12} md={12}>

            <Form onSubmit={formik.handleSubmit} id="regform">
                  <Form.Row>
                    <Form.Group as={Col} controlId="cntcFname">
                      <Form.Label htmlFor="firstName">First Name{formik.touched.firstName && formik.errors.firstName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.firstName}</span>) : null}</Form.Label>
                      <Form.Control id="firstName" type="text" {...formik.getFieldProps('firstName')} size='sm'/>
                    </Form.Group>
  
                    <Form.Group as={Col} controlId="cntcLname">
                      <Form.Label htmlFor="lastName">Last Name{formik.touched.lastName && formik.errors.lastName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.lastName}</span>) : null}</Form.Label>
                      <Form.Control id="lastName" type="text" {...formik.getFieldProps('lastName')} size='sm' />
                    </Form.Group>
                  </Form.Row>
                  <Form.Row>
                    <Form.Group as={Col} controlId="cntcEmail">
                        <Form.Label htmlFor="email">Email Address{formik.touched.email && formik.errors.email ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.email}</span>) : null}</Form.Label>
                        <InputGroup size='sm'>
                        <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text></InputGroup.Prepend>
                        <Form.Control id="email" type="email" {...formik.getFieldProps('email')} size='sm' />
                        </InputGroup>
                    </Form.Group>
    
                    <Form.Group as={Col} controlId="cntcPhone">
                        <Form.Label htmlFor="phone">Phone{formik.touched.phone && formik.errors.phone ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.phone}</span>) : null} </Form.Label>
                        <InputGroup size='sm'>
                        <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text></InputGroup.Prepend>
                        <Form.Control id="phone" type="text" {...formik.getFieldProps('phone')} size='sm' />
                        </InputGroup>
                    </Form.Group>

                  </Form.Row>

                  

                  <Form.Group controlId="cntcInq">
                    
                    <Form.Label htmlFor="phone">Your Inquiry{formik.touched.inq && formik.errors.inq ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.inq}</span>) : null} </Form.Label>
                    <InputGroup size='sm'>
                      <Form.Control id="inq" as="textarea" rows="5" {...formik.getFieldProps('inq')} size='sm' value={`Hello, I would like to receive more information about your ${props.theitemdesc} (${props.theitemsno}). Could you please get in contact with me?`} />
                    </InputGroup>
                  </Form.Group>

                  <Form.Control id="itemdesc" type="text" {...formik.getFieldProps('itemdesc')} size='sm' disabled />
                  

                  <Button id="inqsendbtn" variant="primary" type="submit" style={{width: '100%', marginBottom: '0px'}} disabled={inqSent}>     {sendBtnTxt}&nbsp;&nbsp; <FontAwesomeIcon icon={faPaperPlane} />     </Button>
  
              </Form>
  
              <span style={{fontSize: '12px'}}><Link to={`/terms`}>Terms & Conditions.</Link> </span>
  
            </Col>
  
  
          </Row>
        </Container>
  
        </Modal.Body>
        {/* <Modal.Footer>
          
        </Modal.Footer> */}
      </Modal>
    );
  }

  export default MyVerticallyCenteredModal;