import React from 'react'

import { Button } from 'react-bootstrap';

import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import LazyHero from 'react-lazy-hero';
import introbg from '../intro-bg.jpg';



function FPCparallax ( ) {
    return (    
    
    
    <Row>
      <Col sm={12} md={12} style={{width: '100%', padding: '0px'}}>
      {/* 2e3957 */}
        <LazyHero imageSrc={introbg} isFixed='true' parallaxOffset='100' color='#000000' minHeight='60vh' maxWidth='100%'>
                
                <div style={{color: 'white', marginTop: '-30px'}}>
                  <h1>Keeping Auctions Live<sup>&trade;</sup></h1>
                  <br />
                  <Button variant='warning' size='lg'>Start Bidding Now</Button>
                  
                  
                </div>

        </LazyHero>

       </Col>
    </Row>

    );
}
export default FPCparallax;