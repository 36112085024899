import React, {useState, useEffect, useRef} from 'react'

import {useFormik} from 'formik';
import * as Yup from 'yup';

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'
import CurrencyFormat from 'react-currency-format';

import {Button, Modal, FormText} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup'

import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel, faComments } from '@fortawesome/free-solid-svg-icons'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Countdown from 'react-countdown';

import noimgcar from '../assets/img/img-placeholder.jpg';
import noimgbike from '../assets/img/img-placeholder-bike.jpg';
import noimghouse from '../assets/img/img-placeholder-house.jpg';
import noimgmisc from '../assets/img/img-placeholder-misc.jpg';
import noimggeneric from '../assets/img/img-placeholder-generic.jpg';
import noimgequip from '../assets/img/img-placeholder-equip.jpg';
import noimgatv from '../assets/img/img-placeholder-atv.jpg';

import flagthai from '../assets/img/flagThai.png';
import flagphil from '../assets/img/flagPhil.png';

import Slider from "react-slick";
import Spinner from 'react-bootstrap/Spinner'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import NavDummyCrumbs from './NavDummyCrumbs';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PopoverContent from 'react-bootstrap/PopoverContent'
import PopoverTitle from 'react-bootstrap/PopoverTitle'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
//import { baseUrl } from "./config";

import ItemInquiryForm from './ModalItemInquiry.js'
import ItemInquiryTy from './ModalItemInquiryTy.js'

import ItemPackageDets from './ItemPackageDets.js';
import "../../node_modules/draft-js/dist/Draft.css";



function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

var showconf = true;
var thebamnt = 0;
var currbid = 0;
var askprc = 0;
var oktobid = true;
var biddesc = 'STARTING';
var bbanotmin = true;


function placebid(eqpn) {

    var thisItem = eqpn;

    var frm = document.forms["bidform"];
    var thisBid = frm.bidamount.value;
    thebamnt = frm.bidamount.value;
    
    var pbvarcont = document.forms["pbvarcont"];
    var tola =  pbvarcont.tola.value;
    var ps_ubidlim =  pbvarcont.ubidlim_ps.value;
    var ps_minabid =  pbvarcont.minabid_ps.value;
    var ps_currbid =  pbvarcont.currbid_ps.value;
    var ps_bidinc =  pbvarcont.bidinc_ps.value;
    var ps_currmaxbid =  pbvarcont.currmaxbid_ps.value;

    var tfvc = document.forms["luibsCont"];
    

    //PRELIMINARY CHECKS ON BIDFORM
    if (thisBid == ""){alert("Please Enter A Bid Amount");showconf=false;return showconf;}
        
    var Chars = "0123456789";
    var strbid = new String() ;
    var re = new RegExp (',', 'gi') ;
    for (var i = 0; i < frm.bidamount.value.length; i++) { 
        if (Chars.indexOf(frm.bidamount.value.charAt(i)) == -1) {alert("Invalid Character(s)\n\nOnly numbers (0-9) are allowed.");showconf=false;return showconf;}
    }
    strbid = frm.bidamount.value;
    // Replace comma with nothing.
    
    var newstrbid = strbid.replace(re, '') ;
    //alert("bid:"+ Number(newstrbid)  + "| mina:" + Number(ps_minabid));
    if ( Number(newstrbid) < Number(ps_minabid) ) {alert('Your Bid Amount must be GREATER THAN or EQUAL TO the minimun acceptable bid.');showconf=false;return showconf;}
    
    var divByInc = (newstrbid-ps_currbid)/ps_bidinc;
    var thisInt = Math.floor(divByInc);
    var decipart = divByInc - thisInt;
    if (decipart > 0) {alert('Please enter a bid amount by the bid increment or multiples thereof.');showconf=false;return showconf;}
    if ( Number(newstrbid) > Number(ps_ubidlim) && Number(ps_ubidlim) !== 0  ){	alert('Your Bid Amount exceeds your Bid Limit.');showconf=false;return showconf;}

    //CHECK FOR LATEST USER AND ITEM BID STATUSES
    getLatestUIBStat(eqpn,tola);
    setTimeout(function(){
        
        dspnewdata();

        //NEW BID DETECTED
        //alert(ps_currbid); alert(tfvc.icurrbid.value);
        if ((Number(ps_currbid) != Number(tfvc.icurrbid.value)) && (tfvc.icurrbid.value != "")) {
            
            // document.getElementById("pb3").style.display = "none";
            // document.getElementById("pb2").style.display = "block";
            // //alert(tfvc.bmina.value);
            // document.getElementById("warnbox").style.display = "block";
            ///////////////////////////////////////////===============================================================================
            document.getElementById("sendingbmsg").style.display = "none"; 
            document.getElementById("confbutton").style.display = "none";
            document.getElementById("pbalertmsgbot").innerHTML = "Another bid was registered. Please edit you bid amount.";
            // document.getElementById("dsp_mina").innerHTML = "USD "+formatCurrency(tfvc.bmina.value);
            // document.getElementById("dsp_bidi2").innerHTML = "Bid Increment: "+formatCurrency(tfvc.ibidi.value);



            showconf=true;
            
            setTimeout(function(){
                window.location = `/forsale/${eqpn}`;
            },2000);

            
            return showconf;;

        }		
        
        //IF USER IS STILL A BIDDER AND NO BID DETECTED SINCE ITEM PAGE LOAD
        
        // if (tfvc.uisbidder.value == 1){
        //     document.getElementById("warnbox").style.display = "none";
            
        //     /////////placebid3(eqpn);
            
            
        // }else{
        //     alert("no more a bidder");
        //     //redirect
        // }

        showconf=true;return showconf;
    
    },500);

        

}

function getLatestUIBStat(en,ola){
    // var params = "uid=<cfoutput><cfif parameterexists(cookie.tuid)>#cookie.tuid#<cfelse>0</cfif></cfoutput>&enum="+en+"&aucid="+ola;
    // http( "POST" , "/v6/com/olaModLib.cfc?method=latestUIBStat" , getLatestUIBStat_cb, params );

    var islogged = readCookie('islogged');
    var cuid = 0; if (islogged>0){cuid=islogged}

    fetch(`https://bidlive.bid/rest/proja/api/latestUIBStat?uid=${cuid}&enum=${en}&aucid=olaia`)
    .then(res => res.json())
    .then(
      (result) => {

        var tfvcont = document.forms["luibsCont"];
        tfvcont.uisbidder.value = result[0].UISBIDDER;
        tfvcont.ubidlim.value = result[0].UBIDLIM;
        tfvcont.ibidstart.value = result[0].IBIDSTART;
        tfvcont.ibidi.value = result[0].IBIDI;
        tfvcont.inumbids.value = result[0].INUMBIDS;
        tfvcont.icurrbid.value = result[0].ICURRBID;
        tfvcont.ihibidder.value = result[0].IHIBIDDER;
        tfvcont.icurrmaxbid.value = result[0].ICURRMAXBID;
        tfvcont.imaxbidder.value = result[0].IMAXBIDDER;
        tfvcont.ireserve.value = result[0].IRESERVE;
        tfvcont.ibuynow.value = result[0].IBUYNOW;
        tfvcont.iendcd.value = result[0].IENDCD;
        tfvcont.ienddsp.value = result[0].IENDDSP;

        // console.log('tfvcont.icurrbid.value', tfvcont.icurrbid.value)
        

        //determine new minimum bid allowed
		var mina = 0;
		if (result[0].ICURRBID == ""){	var bam = Number(result[0].IBIDSTART);}else{var bam = Number(result[0].ICURRBID);}
		//if the user has active maxbid on the item
		if (Number(tfvcont.uid.value) == Number(result[0].IMAXBIDDER)) {
			mina = Number(result[0].ICURRMAXBID) + Number(result[0].IBIDI);
		}else{
			//if user is the current highest bidder on the item
			if (Number(tfvcont.uid.value) == Number(result[0].HIBIDDER)) {
				mina = bam	+ (Number(result[0].IBIDI) * 2);
			}else{
				mina = bam	+ Number(result[0].IBIDI);
			}
		}
        tfvcont.bmina.value = mina;
        
        var pbvarcont = document.forms["pbvarcont"];
		pbvarcont.ubidlim_ps.value = result[0].UBIDLIM;
		pbvarcont.minabid_ps.value =  mina;
		//alert("result[0].icurrbid:"+result[0].icurrbid);
		if (result[0].ICURRBID == ""){
			pbvarcont.currbid_ps.value =  result[0].IBIDSTART;
		}else{
			pbvarcont.currbid_ps.value =  result[0].ICURRBID;
		}
		
		pbvarcont.bidinc_ps.value =  result[0].BIDI;
		pbvarcont.currmaxbid_ps.value =  result[0].ICURRMAXBID;
        
      },
      (error) => {}
    )

}

function dspnewdata(){
    var tfvc = document.forms["luibsCont"];
    
    //CURRENT BID AND INCREMENT
    //
    // console.log('tfvc.icurrbid.value',tfvc.icurrbid.value);
    if (tfvc.icurrbid.value == ""){
        document.getElementById("dsp_currbid").innerHTML = String.fromCharCode(8369)+" "+formatCurrency(tfvc.ibidstart.value);
    }else{
        document.getElementById("currbidcont").innerHTML = "Current Bid";
        document.getElementById("dsp_currbid").innerHTML = String.fromCharCode(8369)+" "+formatCurrency(tfvc.icurrbid.value);
        
    }
    document.getElementById("dsp_bidi").innerHTML = String.fromCharCode(8369)+" "+formatCurrency(tfvc.ibidi.value);
    
    //END TIME COUNTDOWN AND DISPLAY TEXT
    //document.getElementById("countdown1").innerHTML = tfvc.iendcd.value;
    //document.getElementById("dsp_edt").innerHTML = tfvc.ienddsp.value;
    
    //RESERVE PRICE
    /////=========================================================================================
    if ((tfvc.icurrbid.value < tfvc.ireserve.value) && tfvc.ireserve.value != 0){
        //document.getElementById("rpcont").style.display = "block";
        //document.getElementById("dsp_reserve").style.display = "block";
    }else{
        //document.getElementById("rpcont").style.display = "none";
        //document.getElementById("dsp_reserve").style.display = "none";
    }
    
    //BUY NOW PRICE
    if (tfvc.ibuynow.value != 0){
        //document.getElementById("bnpcont").style.display = "block";
        //document.getElementById("dsp_bnp").style.display = "block";
        //document.getElementById("dsp_bnp").innerHTML = "USD "+formatCurrency(tfvc.ibuynow.value);
        //document.getElementById("bnplnk").style.display = "block";
    }else{
    
        //document.getElementById("bnpcont").style.display = "none";
        //document.getElementById("dsp_bnp").style.display = "none";
        //document.getElementById("bnplnk").style.display = "none";
    }
    /////=========================================================================================
    //CURRENT NUMBER OF BIDS AND START BID
    //document.getElementById("dsp_numbids").innerHTML = tfvc.inumbids.value;
    //document.getElementById("dsp_bidstart").innerHTML = formatCurrency(tfvc.ibidstart.value);
    
    //======================================
    // countdown timer initialize
    //CD_Init();
    //=================================
}
//===============================================================================================

  









function formatCurrency(num) {
    var num = num.toString().replace(/\$|\,/g,'');
    if(isNaN(num))
        num = "0";
    var sign = (num == (num = Math.abs(num)));
    num = Math.floor(num*100+0.50000000001);
    var cents = num%100;
    num = Math.floor(num/100).toString();
    if(cents<10)
        cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
    num = num.substring(0,num.length-(4*i+3))+','+
    num.substring(num.length-(4*i+3));
    return (((sign)?'':'-') + '' + num + '.' + cents);
}

//=====================================================================================================================================================================================================
function Categidets ({match}) {

    

    const [eqpd, setEqpd] = useState([]);
    const [ibstat, setIbstat] = useState([]);
    const [imgarr, setImgarr] = useState([]);
    const [ofimgnams,setofimgnams] = useState([]);
    const [errmess, setErrmess] = useState([]);
    const [tcno, setTcno] = useState(0);
    const [teno, setTeno] = useState(0);

    const [numbids, setnumbids] = useState(0);
    const [hibid, sethibid] = useState(0);
    const [hibidder, sethibidder] = useState(0);
    const [maxbid, setmaxbid] = useState(0);
    const [maxbidder, setmaxbidder] = useState(0);
    const [fordefval, setfordefval] = useState(0);
    
    const [currbid_unstable, setcurrbid] = useState(0);
    const [bidi, setbidi] = useState(0);

    const [dontexec, setdontexec] = useState(0);

    const [modalShow, setModalShow] = useState(false);
    const [modalShowty, setModalShowty] = React.useState(false);
    

    const [show, setShow] = useState(false);
    const [strtdat, setstrtdat] = useState('');
  
    const handleClose = () => setShow(false);
    
    const handleCloseReload = (eqpn) => {
        setShow(false);
        window.location = `/forsale/${eqpn}`;
    }
    const handleShow = () => setShow(true);
    
    const cunam = readCookie('uname'); 
    const cbidno = readCookie('bidno');
    const capproved = readCookie('approved');
    const cuserid = readCookie('userid');
    const cemail = readCookie('email');
    const cbidlimit = readCookie('bidlimit');
    const islogged = readCookie('islogged');
    const [cmina, setcmina] = useState('');

    const [showbidamntmsg, setbidamntmsg] = useState(false);
    const [showbbmsg, setbbmsg] = useState(false);
    const [withthisamnt, setwiththisamnt] = useState('');
    const [target, setTarget] = useState(null);
    const ref = useRef(null);
    const refbb = useRef(null);

    
    const handleMOver = (event) => {
        setbidamntmsg(true);
        setTarget(event.target);
    };
    const handleMOut = (event) => {
        
        setbidamntmsg(false);
        setTarget(event.target);
    };
    const handleBBMOver = (event) => {
        var frm = document.forms["bidform"];
        var thisBid = frm.bidamount.value;
        thebamnt = frm.bidamount.value;
        setwiththisamnt(thebamnt);
        setbbmsg(true);
        setTarget(event.target);
    };
    const handleBBMOut = (event) => {
        setbbmsg(false);
        setTarget(event.target);
    };


  
    useEffect(() => {
        fetch(`https://bidlive.bid/rest/proja/api/getidets?olaid=DAX1&equipnum=${match.params.equipnum}`)
        .then(res => res.json())
        .then(
          (result) => {
            setEqpd(result);
            
            setcurrbid(result[0].bidstart)
            //setbidi(result[0].bidincrmnt)
            setstrtdat(result[0].startdate)

         

            for (var {consignorno: cno, equipno: eno, bidstart: bidstart} of result) {
                setTcno(cno);
                setTeno(eno);
            }

            let imgarr_ = [];
            for (let i = 0; i < 20; i++) {
                const key = "image" + i;
                const value = result[0][key];
                if (value !== "") {
                    imgarr_.push(value);
                }
            }
            setImgarr(imgarr_);

            //console.log('imgnames:', result[0].imgnames);

            var ofimgnams_tmp=result[0].imgnames.replace(/'/g, "").split(",");
            
            setofimgnams(ofimgnams_tmp);
            sessionStorage.setItem('aucsite', result[0].aucsiteid);
            

          },
          (error) => {setErrmess(error);console.log(error)}


        )
   }, [dontexec])

   useEffect(() => {
    fetch(`https://bidlive.bid/rest/proja/api/bidstat?olaid=DAX1&equipnum=${match.params.equipnum}`)
    .then(res => res.json())
    .then(
      (result) => {

        
        setIbstat(result);

        setnumbids(result[0].NUMBIDS)
        sethibid(result[0].HIBID)
        sethibidder(result[0].HIBIDDER)
        setmaxbid(result[0].MAXBID)
        setmaxbidder(result[0].MAXBIDDER)
        
        if(result[0].NUMBIDS>0){
            currbid = result[0].HIBID;
        }

        // console.log('askprc:', askprc)
        // console.log('currbid:', currbid)
        // console.log('result[0].INCRMNT:', result[0].INCRMNT)

        askprc = currbid + result[0].INCRMNT;
        setbidi(result[0].INCRMNT)
        if (!!result[0].HIBID){
            setcurrbid(result[0].HIBID)
        }
        
        setwiththisamnt(askprc);
        
        

      },
      (error) => {setErrmess(error);console.log(error)}


    )
    window.scrollTo(0, 0)
    }, [match.params.equipnum])

  
    

   
    const getplaceholder = ({ catid }) => {

        switch (catid) {
        case 'ATV': return noimgatv;
        case 'AUT': return noimgcar;
        case 'PUT': return noimgcar;
        case 'PRP': return noimghouse;
        case 'MIS': return noimgmisc;
        case 'MTC': return noimgbike;
        case 'HEX': return noimgequip;
        default: return noimggeneric;
        }
        
    };

    const settings = {
        customPaging: function(i) {
            return (
            // <a><img src={`https://bidlive.bid/photos/olaitems/olaia-${tcno}-${teno}_${i + 1}.jpg?${new Date().getTime()}`} /></a>
            <a><img src={`https://bidlive.bid/photos/olaitems/${ofimgnams[i]}?${new Date().getTime()}`} style={{padding: "1px"}} /></a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    const baseURL = 'https://bidlive.bid/photos/olaitems'
    
    

    
    if (numbids > 0) {
        currbid = hibid;
        biddesc = 'CURRENT';
    }else{
        currbid = currbid_unstable;
        biddesc = 'STARTING';
    }

    let ngaun = new Date;
    let istrtdat = new Date(strtdat);
    if (cbidno>0 && capproved>0){
        if (ngaun < istrtdat){
            oktobid = false;
            // console.log('not good to bid');
        }else{
            oktobid = true;
            // console.log('good to bid');
        }
    }else{
        oktobid=false;
    }

    
    
    
    
    
    
    //getLatestUIBStat(match.params.equipnum,'olaia')

    //console.log("currentbid:", currbid);
    //console.log("bidi:", bidi)

    const minAllowed = currbid + bidi;
    
    var now = new Date();
    now.setTime(now.getTime() + 2 * 3600 * 1000);
    document.cookie = "cminallowed="+currbid+"; expires=" + now.toUTCString() + "; path=/";
    
    const cdrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            
            // window.location.href = "http://ww2.bidlive.bid";
            window.location = `/`;
        }
        // Render a countdown
        return (
          <span>
            {days}d {hours}h {minutes}m {seconds}s
          </span>
        );
      };
                    





      // var pbvarcont = document.forms["pbvarcont"];
    
    // console.log('pbvarcont.minabid_ps.value',pbvarcont.minabid_ps.value);

    // useEffect(()=>{
    //     var pbvarcont = document.forms["pbvarcont"];
	//     pbvarcont.nxtminbid.value =  minAllowed;
    // })
    
        
        
    //setfordefval(fordefval=>minAllowed);

// ====================================================================================================================================================
//  PLACE THE ACTUAL BID ==============================================================================================================================
// ====================================================================================================================================================
    function handleSubmitBid(eqpn,tuid) {

        var frm = document.forms["bidform"];

		var thisbid = frm.bidamount.value;
		var thisequip = eqpn;
        var thisuser = tuid;
        // alert(eqpn);
        // alert(tuid);
		
		var tfvc = document.forms["luibsCont"];
						
		var pbvarcont = document.forms["pbvarcont"];
		var tola =  pbvarcont.tola.value;
		var ps_currbid =  pbvarcont.currbid_ps.value;
		
        document.getElementById("confbmsg").style.display = "none";
        document.getElementById("confbutton").style.display = "none";
		document.getElementById("sendingbmsg").style.display = "block";
				
		// FIRST CHECK THE LATEST USER AND ITEM BID STATISTICS
		getLatestUIBStat(eqpn,tola);
		setTimeout(function(){
			
			//DISPLAY NEW OR OLD DATA ON DIVS OR SPANS
			dspnewdata();
			
			//NEW BID DETECTED
			//alert(ps_currbid); alert(tfvc.icurrbid.value);
			if ((Number(ps_currbid) != Number(tfvc.icurrbid.value)) && (tfvc.icurrbid.value != "")) {
                document.getElementById("sendingbmsg").style.display = "none";
                document.getElementById("mainpbc").style.display = "none";
				//document.getElementById("pb2").style.display = "block";
				//alert(tfvc.bmina.value);
				//document.getElementById("warnbox").style.display = "block";
				document.getElementById("pbalertmsgbot").innerHTML = "Another bid was registered. Please edit you bid amount.";
				//document.getElementById("dsp_mina").innerHTML = "USD "+formatCurrency(tfvc.bmina.value);
                //document.getElementById("dsp_bidi2").innerHTML = "Bid Increment: "+formatCurrency(tfvc.ibidi.value);
                
                //close the modal
				return false;
			}		
            
            
			//IF USER IS STILL A BIDDER AND NO BID DETECTED SINCE ITEM PAGE LOAD
			//if (tfvc.uisbidder.value == "1"){
			
				//document.getElementById("warnbox").style.display = "none";
		
				var icurrbid = Number(tfvc.icurrbid.value);
				var icurrbidder = Number(tfvc.ihibidder.value);
				var bidinc = Number(tfvc.ibidi.value);
				var imaxbid = Number(tfvc.icurrmaxbid.value);
				var imaxbidder = Number(tfvc.imaxbidder.value);
				
				var nrmlbidcap = Number(ps_currbid)+(bidinc*2);
				
				//PLACE THE ACTUAL BID
				placeBidNow(thisuser,thisequip,thisbid,nrmlbidcap,imaxbid,imaxbidder,bidinc,icurrbid,icurrbidder);
				setTimeout(function(){
				
					//document.getElementById("pb3").style.display = "none";
					//document.getElementById("pb4").style.display = "block";
					var ppbvc = document.forms["postBidVarCont"];
					//document.getElementById("pbalertmsgbot").innerHTML = ppbvc.placebidmess.value;
					
					getLatestUIBStat(eqpn,tola);
					setTimeout(function(){
						dspnewdata();
					},500);
					
					// UNCOMMENT TO RELOAD PAGE
					//setTimeout(function(){
					//	window.location = "/forsale/"+thisequip;
					//},5000);

					
				},1000);
				
				
				
			//}else{
			//	alert("no more a bidder");
				//TODO: Redirect
			//}
			
		
		},1000);

        //close the modal
       
    };
    
    function placeBidNow(uid,eqpn,bidamnt,nbcap,imaxb,imaxbidder,binc,icb,icbidder){
		var params = "uid="+uid+"&enum="+eqpn+"&bidamnt="+bidamnt+"&nrmlbidcap="+nbcap+"&imaxb="+imaxb+"&imaxbidder="+imaxbidder+"&ibidinc="+binc+"&icurrbid="+icb+"&icurrbidder="+icbidder;
        //http( "POST" , "/v6/com/olaModLib.cfc?method=placeBidNow" , placeBidNow_cb, params );
        fetch(`https://bidlive.bid/rest/proja/api/placeBidNow?${params}`)
        .then(res => res.json())
        .then(
        (result) => {
            // console.log(result);  
            var ppbvc = document.forms["postBidVarCont"];
            

            

            document.getElementById("sendingbmsg").style.display = "none";
            document.getElementById("sendingbmsg").innerHTML = "Done";
            document.getElementById("pbalertmsgbot").innerHTML = result.DATA[0][0];
            document.getElementById("pbalertmsgbot").style.color = result.DATA[0][0].search(/You are the highest bidder/i)>0?"green":"red";
            

            // setTimeout(function(){
			// 	window.location = `/forsale/${eqpn}`;
			// },1000);

            //setTimeout(function(){
            //    window.location = `/forsale/${eqpn}`;
            //},500);

		    //ppbvc.placebidmess.value = obj.pbm;
        
        },
        (error) => {}

        )

    }
    

    return(


    <Container fluid>
        
    <Navtop /> 



      <Row style={{borderTop: '1px solid #cccccc'}}><Col sm={12} md={12} style={{maxWidth: '1140px', color: 'black', borderTop: '0px solid #cccccc'}}> 
        
      </Col></Row> 
      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black'}}>

      <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
          {eqpd.map(idet => (
          <Container key={idet.equipnum}>
                
                <Row><Col xs={12} sm={12} md={12}>
                        <NavDummyCrumbs props={idet.countryid} /> 
                </Col></Row>


              <Row>
                <Col sm={12} md={12}>
                    {/* {match.params.equipnum}    {errmess} */} &nbsp;
                </Col>

              </Row>
              <Row>
                <Col sm={12} md={6} className="d-flex justify-content-center" style={{position: 'relative'}}>

                    <Container>
                    <Row>
                    <Col>

                    <div style={{position: 'relative'}}>

                    
                    
                    {
                        ofimgnams.length > 0
                            // IF THERE IS AN IMAGE
                            ? 
                            (
                                ofimgnams.length > 1
                                //IF MORE THAN ONE IMAGE
                                ? (
                                <div className="container" style={{width: '500px', paddingLeft: '0px', paddingRight: '0px', position: 'relative'}}>
                                    <Slider {...settings}>
                                        {ofimgnams.map(timg => (
                                            <div key={timg} style={{position: 'relative'}}>
                                                <img src={`https://bidlive.bid/photos/olaitems/${timg}?${new Date().getTime()}`} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                )
                                // ONLY ONE IMAGE                
                                : (
                                    <img src={`https://bidlive.bid/photos/olaitems/${ofimgnams[0]}?${new Date().getTime()}`} />    
                                )
                            )

                            
                                // <div className="container" style={{width: '500px', paddingLeft: '0px', paddingRight: '0px'}}>
                                // {/* <img src={`https://bidlive.bid/photos/olaitems/${idet.image0}`} /> */}
                                //     <Slider {...settings}>
                                //         {imgarr.map(timg => (

                                //             <div>
                                //                 <img src={`https://bidlive.bid/photos/olaitems/${timg}`} />
                                //             </div>
                                //         ))}
                                //     </Slider>
                                // </div>


                            // IF THERE IS NO IMAGE
                            : <div style={{border: '1px solid #cccccc', width: 400, height: 300, paddingLeft: '40px', paddingTop: '40px'}}>
                                {(() => {
                                    switch (idet.catid.trim()) {
                                        case 'ATV': return (<img src={noimgatv} />);
                                        case 'AUT': return (<img src={noimgcar} />);
                                        case 'PUT': return (<img src={noimgcar} />);
                                        case 'PRP': return (<img src={noimghouse} />);
                                        case 'MIS': return (<img src={noimgmisc} />);
                                        case 'MTC': return (<img src={noimgbike} />);
                                        case 'HEX': return (<img src={noimgequip} />);
                                        default: return (<img src={noimggeneric} />)
                                    }
                                })()}
                            </div>
                    }
                    </div>
                    </Col>
                    </Row>

                    <Row>
                    <Col>
                    
                    {/* ------------------------------------------------------------------------------------------------------------------------------------ */}

                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '30px'}}>

                    <Row>
                        <Col xs={12} sm={12} md={12} style={{fontSize: '14px',marginBottom: "15px", paddingTop: "6px", paddingBottom: "6px", fontWeight: "bold", backgroundColor: "#dedede"}}>PACKAGE/PROMO DETAILS</Col>
                        
                    </Row>
                    {idet.mpkgdets?(
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    
                                    <ItemPackageDets mpkgdets={idet.mpkgdets} />
                                </Col>

                            </Row>
                    ):null}


                    {idet.mileage!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>MILEAGE</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.mileage} {idet.mileageunit}</Col>
                    </Row>
                    ):null}
                    {idet.hours!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>HOURS</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.hours}</Col>
                    </Row>
                    ):null}
                    {idet.sno!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>VIN/SERIAL NO.</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.sno}</Col>
                    </Row>
                    ):null}
                    {idet.enginetype!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>ENGINE TYPE</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.enginetype}</Col>
                    </Row>
                    ):null}
                    {idet.horsepower!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>HORSE POWER</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.horsepower}</Col>
                    </Row>
                    ):null}
                    {(idet.year!==""||idet.year!=0)?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>YEAR</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.year}</Col>
                    </Row>
                    ):null}
                    {idet.grntlight!=="na"?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>LIGHTS</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            <span style={{backgroundColor: `${idet.grntlight}`, color: '#cccccc', borderRadius: '3px', padding: '5px'}}>{idet.grntlight}</span>
                        </Col>
                    </Row>
                    ):null}
                    {/* idet.autkeys!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>KEYS</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.autkeys}
                        </Col>
                    </Row>
                    ):null */}
                    {idet.bodystyle!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>BODY STYLE</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.bodystyle}
                        </Col>
                    </Row>
                    ):null}
                    {idet.trim!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TRIM</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.trim}
                        </Col>
                    </Row>
                    ):null}
                    {idet.colorint!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>INTERIOR COLOR</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.colorint}
                        </Col>
                    </Row>
                    ):null}
                    {idet.colorext!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>EXTERIOR COLOR</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.colorext}
                        </Col>
                    </Row>
                    ):null}
                    {idet.odostat!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>ODOMETER STATUS</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.odostat}
                        </Col>
                    </Row>
                    ):null}
                    {idet.drive!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>DRIVE</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.drive}
                        </Col>
                    </Row>
                    ):null}
                    {idet.transtype!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TRANSMISSION</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.transtype}
                        </Col>
                    </Row>
                    ):null}
                    {idet.cartitle!==""?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TITLE</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            {idet.cartitle}
                        </Col>
                    </Row>
                    ):null}
                    {idet.caropt!=="these"?(
                    <Row>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>CAR OPTIONS:</Col>
                        <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                            
                            {(() => { 
                                var arr = idet.caropt.split(",");
                                var arr_fer = arr.shift();
                                return arr.toString();
                            })()}

                        </Col>
                    </Row>
                    ):null}

                    </Container>

                    {/* ------------------------------------------------------------------------------------------------------------------------------------ */}
                    </Col>
                    </Row>
                    </Container>

                </Col>
                <Col sm={12} md={6} style={{color: 'black', marginLeft: '0px', paddingLeft: '0px'}}>

                
                    <div style={{fontSize: '18px', fontWeight: 'bold'}}>
                        {idet.shortdesc}
                    </div>
                    <div style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>{idet.addinfo} {idet.addinfo2?`(${idet.addinfo2})`:''}</div>
                    
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '10px'}}>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>PROMO CODE</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '18px', fontWeight: 'bold'}}>{idet.aucsiteid=='AP1'?idet.catalognostr:idet.equipnum}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>LOCATION</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '14px', fontWeight: 'normal'}}>
                                {idet.locmap!==""?<>
                                    <a href={idet.locmap} target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                </>:<>
                                    {idet.itemloc}
                                </>}

                                
                                {/* 
                                {idet.countryid=="US"?<>
                                    <a href="https://goo.gl/maps/XuTfUG92EVRCxMD27" target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                </>:<>
                                    <a href="https://maps.app.goo.gl/NUhMmQkP4yREznhz6" target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                    
                                </>}
                                */}
                                
                                {/* {idet.countryid==='TH'?
                                    <img src={flagthai}/>
                                :
                                    <img src={flagphil}/>
                                } */}
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>AUCTION DATE</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '14px', fontWeight: 'normal'}}>
                               
                                Starts:&nbsp;&nbsp;
                                {(() => { 
                                    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                    let current_datetime = new Date(idet.startdate)
                                    let formatted_date = months[current_datetime.getMonth()]+" "+current_datetime.getDate() + ", " + current_datetime.getFullYear()
                                    //console.log(formatted_date)
                                    return formatted_date;
                                })()}

                                <span>&nbsp;&nbsp;[Ends In:&nbsp;&nbsp;<Countdown date={idet.enddate} renderer={cdrender} />]</span>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>
                                <span id='currbidcont' style={{fontSize: '12px'}}>{biddesc} BID</span>
                            </Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '18px', fontWeight: 'bold'}}>
                            
                                {/* {console.log('numbids:', numbids)}
                                {(() => {
                                    if(numbids==0){
                                        setcurrbid(idet.bidstart)
                                    }else{
                                        setcurrbid(hibid)
                                    }

                                })()} */}

                                {/* console.log('reservedprice:',idet.reservedprice,'hibidder',hibidder) */}

                                
                                <span id='dsp_currbid'>
                                    <CurrencyFormat id='currbidcont_frmtd' value={currbid} displayType={'text'} thousandSeparator={true} prefix={String.fromCharCode(idet.auctionid=="DAX1"?36:8369)} />
                                    {/* ===========================================
                                     /   IS LOGGED IN USER THE HIGHEST BIDDER
                                     /========================================== */}
                                    &nbsp;<span id="hbmess" style={{color: 'green', fontSize: '12px'}}>
                                        {Number(islogged)===hibidder?<>
                                            'You are the highest bidder'
                                            
                                        </>:null}</span>
                                </span>
                                
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>BID INCREMENT</Col>
                            <Col xs={6} sm={6} md={9} style={{fontSize: '14px', fontWeight: 'bold'}}>
                                
                                <span id='dsp_bidi'><CurrencyFormat value={bidi} displayType={'text'} thousandSeparator={true} prefix={String.fromCharCode(idet.auctionid=="DAX1"?36:8369)} /></span>
                                
                            </Col>
                        </Row>
                    </Container>
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '15px'}}>
                        <Row>
                            
                            <Col sm={12} md={6}>
                            
                            {/* {console.log("minallowed",minAllowed,'[',currbid_unstable,'+',bidi,']')} */}

                            <Form id='bidform' ref={ref}>

                            <InputGroup className="mb-3">
                                <InputGroup.Prepend>
                                    <InputGroup.Text style={{backgroundColor: 'white', borderRightWidth: '0px'}}>{String.fromCharCode(idet.auctionid=="DAX1"?36:8369)}</InputGroup.Text>
                                </InputGroup.Prepend>
                                <FormControl
                                    id="bidamount"
                                    type="text"
                                    defaultValue={withthisamnt}
                                    aria-label="bidamnt"
                                    aria-describedby="bidamnt"
                                    style={{ borderLeftWidth: '0px'}}
                                    disabled={oktobid ? false : true}
                                    onMouseOver={handleMOver}
                                    onMouseOut={handleMOut}
                                    //onFocus={handleMOut}
                                    
                                />
                            </InputGroup>
                            <Overlay
                                show={showbidamntmsg}
                                target={target}
                                placement="top"
                                container={ref.current}
                                containerPadding={20}
                            >
                                <Popover id="popover-contained">
                                <Popover.Title as="h3"><FontAwesomeIcon icon={faGavel} />&nbsp;&nbsp;About your bid...</Popover.Title>
                                <Popover.Content style={{backgroundColor: '#FDFFB6'}}>
                                    If you wish to bid {withthisamnt} now, just click the Place Bid button.<br/>
                                    - OR -<br /><strong>Enter the maximum you want to bid and your bid will outbid other lower bids, up to that amount</strong>
                                </Popover.Content>
                                </Popover>
                            </Overlay>
                            </Form>

                            </Col>
                            <Col sm={6} md={6}>
                                    <>
                                        {/* <Button variant="primary" onClick={handleShow}>
                                        Launch demo modal
                                        </Button> */}

                                        {/* ====================================================================================================================================================
                                        PLACE BID CONFIRMATION MODAL
                                        ==================================================================================================================================================== */}
                                        <Modal show={show} onHide={handleClose} animation={false} centered>
                                            <Modal.Header closeButton style={{backgroundColor: "#FDFFB6"}}>
                                                <Modal.Title>
                                                    <span id='confbmsg' style={{display: 'block'}}>Confirm Bid</span>
                                                    <span id='sendingbmsg' style={{display: 'none'}}><Spinner animation="border" />&nbsp;&nbsp;Placing your bid...</span>
                                                    <span id='pbalertmsgtop' style={{display: 'none'}}><br /></span>

                                                </Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <span id="mainpbc">
                                                {idet.shortdesc}<br/>
                                                <span style={{color: 'black', fontSize: '18px', fontWeight: 'bold'}}>Your Bid: {thebamnt}</span>
                                                </span><br />
                                                <span id="pbalertmsgbot" style={{color: 'black',fontSize: '14px', fontWeight: 'bold'}}></span>

                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={()=>handleCloseReload(idet.equipnum)}>
                                                    Close
                                                </Button>
                                                <Button id="confbutton" variant="primary" onClick={()=>handleSubmitBid(idet.equipnum,islogged)} style={{display: 'block'}}>
                                                    Confirm
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </>

                                
                                <div>

                                    

                                    
                                    {oktobid?(
                                        <span ref={refbb}>
                                        <Button variant="primary" 
                                            onClick={() =>{placebid(idet.equipnum); if(showconf==true){handleShow()}}}
                                            onMouseOut={handleBBMOut}
                                            onMouseOver={handleBBMOver}
                                           >
                                            PLACE BID 
                                        </Button>
                                        <Overlay
                                            show={showbbmsg}
                                            target={target}
                                            placement="top"
                                            container={ref.current}
                                            containerPadding={20}
                                        >
                                            <Popover id="popover-contained">
                                            <Popover.Title as="h3">JUST A CLICK AWAY!</Popover.Title>
                                            <Popover.Content style={{backgroundColor: '#FDFFB6'}}>
                                                <strong>BID {withthisamnt} Now! Just Click!. You will be asked to confirm.</strong>
                                            </Popover.Content>
                                            </Popover>
                                        </Overlay>
                                        </span>
                                    ):(
                                        <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">
                                            {capproved>0?`Item has not started yet. Starts on ${(() => { 
                                                                                    const months = ["Jan", "Feb", "Mar","Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
                                                                                    let current_datetime = new Date(idet.startdate)
                                                                                    let formatted_date = months[current_datetime.getMonth()]+" "+current_datetime.getDate() + ", " + current_datetime.getFullYear()
                                                                                    //console.log(formatted_date)
                                                                                    return formatted_date;
                                                                                })()}
                                            `:'You are not signed in or not yet approved to bid.'}
                                        </Tooltip>}>
                                        <span className="d-inline-block">
                                            <Button variant="secondary" disabled style={{ pointerEvents: 'none' }}> PLACE BID </Button>      
                                        </span>
                                        </OverlayTrigger>
                                        
                                    )}

                                    


                                </div>
                            </Col>





                        </Row>
                    </Container>

                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '0px'}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>

                            </Col>
                        </Row>
                    </Container>

                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '0px'}}>
                        <Row>
                            <Col xs={12} sm={12} md={12} style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>
                                <div style={{width: '100%', borderBottom: '1px solid #dedede', fontSize: '12px', fontWeight: 'bold', marginBottom: '5px'}}>Other Sales Terms</div>
                                {idet.addsterms!==""?<div style={{backgroundColor: '#fdffb6', padding: '5px'}}>{idet.addsterms}</div>:''}
                            </Col>
                        </Row>
                    </Container>


                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '5px'}}>
                        {idet.reservedprice==0?(
                        <Row>
                            <Col xs={6} sm={6} md={4} style={{fontSize: '12px', paddingBottom: "4px"}}>SALE TYPE</Col>
                            <Col xs={6} sm={6} md={8} style={{fontSize: '12px', paddingBottom: "4px", fontWeight: 'bold'}}>UNRESERVED</Col>
                        </Row>
                        ):null}
                        {idet.buynowprice!==0?(
                        <Row>
                            <Col xs={6} sm={6} md={4} style={{fontSize: '12px', paddingBottom: "4px"}}>BUY NOW PRICE</Col>
                            <Col xs={6} sm={6} md={8} style={{fontSize: '12px', paddingBottom: "4px", fontWeight: 'bold'}}>{String.fromCharCode(8369)+" "+formatCurrency(idet.buynowprice)}</Col>
                        </Row>
                        ):null}
                        {idet.buyerPremium!==0?(
                        {/* <Row>
                            <Col xs={6} sm={6} md={4} style={{fontSize: '12px', paddingBottom: "4px"}}>BUYER'S PREMIUM</Col>
                            <Col xs={6} sm={6} md={8} style={{fontSize: '12px', paddingBottom: "4px", fontWeight: 'bold'}}>{idet.buyerPremium}&nbsp;%</Col>
                        </Row> */}
                        ):null}
                    </Container>

                    


                    <br />

                    <Button size='sm' onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faComments} />&nbsp;&nbsp;Inquire About This Item</Button>

                    <ItemInquiryForm
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onNext={() => setModalShowty(true)}
                        theitem = {idet.equipnum}
                        theitemdesc = {idet.shortdesc}
                        theitemsno = {idet.sno}
                    />
                    <ItemInquiryTy
                        show={modalShowty}
                        onHide={() => setModalShowty(false)}
                    />
                    
                
                    <form id="pbvarcont" name="pbvarcont">
                        <input type="hidden" name="tola" value="olaia" />
                        <input type="hidden" name="ubidlim_ps" value={cbidlimit} />
                        <input type="hidden" name="minabid_ps" value={minAllowed} />
                        <input type="hidden" name="currbid_ps" value={currbid} />
                        <input type="hidden" name="bidinc_ps" value={bidi} />
                        <input type="hidden" name="currmaxbid_ps" value={maxbid} />
                        <input type="hidden" name="nxtminbid" value='' />
                    </form>

                    <form id="luibsCont" name="luibsCont">
                        <input type="hidden" name="tola" value="olaia" />
                        <input type="hidden" name="uid" value={islogged>0?islogged:0} />
                        <input type="hidden" name="uisbidder" value={islogged ? 1 : 0} />
                        <input type="hidden" name="ubidlim" value={cbidlimit} />
                        <input type="hidden" name="bmina" value={minAllowed} />
                        <input type="hidden" name="ibidstart" value="" />
                        <input type="hidden" name="ibidi" value={bidi} />
                        <input type="hidden" name="inumbids" value="" />
                        <input type="hidden" name="icurrbid" value={currbid} />
                        <input type="hidden" name="ihibidder" value={hibidder} />
                        <input type="hidden" name="icurrmaxbid" value={maxbid} />
                        <input type="hidden" name="imaxbidder" value={maxbidder} />
                        <input type="hidden" name="ireserve" value="" />
                        <input type="hidden" name="ibuynow" value="" />
                        <input type="hidden" name="iendcd" value="" />
                        <input type="hidden" name="ienddsp" value="" />
                        <input type="hidden" name="bprem" value={idet.buyerPremium} />
                        <input type="hidden" name="ienum" value={idet.equipnum} />
                    </form>  

                    <form id="postBidVarCont" name="postBidVarCont">
                        <input type="hidden" name="placebidmess" value="" />
                    </form>  


                    

                
                
                </Col>


              </Row>  
          </Container>

          ))}









          </div>
          <div>
                <br /><br /><br />
             <FPCmiscbar2 />
             <FPCfooter />

            


             
          </div>

    </Col></Row>
    </Container>
    )

}


export default Categidets;

