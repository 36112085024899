import React from 'react'
import { useHistory, Link } from "react-router-dom";



function NavDummyCrumbs() {
    const history = useHistory();
    return (
        <div  style={{color: '#ffba08', fontSize: '14px'}}>

            {/* <Link to={`/`}>Home</Link> &nbsp;/&nbsp; */}
            <a href='http://bidlive.bid'>Home</a> &nbsp;/&nbsp;
            {/* <Link to={`/aucsite/TH1`}>Back to List</Link> &nbsp;/&nbsp; */}
            <Link onClick={() => {
                    history.goBack();
                }}>Back to List</Link> &nbsp;/&nbsp;
            
            {/* {props.countryid=="TH"?<>
            
            </>:<>
            
            </>} */}
            
        </div>
    )
}

export default NavDummyCrumbs

