import React, {useState} from 'react'

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'

import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.js'

import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel, faCheck } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//import { baseUrl } from "./config";
import TermsTxt from './TermsTxt'


function Howtobuy () {

    const [modalShow, setModalShow] = React.useState(false);

    return(
    <Container fluid>
        
    <Navtop /> 


      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>

        <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
        <Container>

        {/* <Link to={`/`}><a href=''>Home</a></Link> */} 
        {/*
        <Row><Col xs={12} sm={12} md={12}>
            <a href='http://bidlive.bid'>Home</a> &nbsp;&nbsp;/&nbsp;&nbsp;  <Link to={`/aucsite/TH1`}><a href=''>Back to List</a></Link>
            &nbsp;&nbsp;/&nbsp;&nbsp;  
        </Col></Row> */}
        
        <Row><Col xs={12} sm={12} md={12}>
        
        <p style={{fontWeight: 'bold', fontSize: '16px'}}>Other Services</p>

        <p style={{fontWeight: 'bold'}}>Bidlive.bid offers an incomparable suite of services to equipment dealers, car dealers, and sellers of all types of commercial and industrial goods.</p>

 

        <p>Including:</p>

 

        <p style={{display: 'flex'}}><FontAwesomeIcon icon={faCheck} style={{fontSize: '18px', width: '3em'}} />A white label version of our timed auction platform that can be used by any dealer to auction their inventory on their own web sites, with complete control over the branding held by the dealer. </p>

 

        <p style={{display: 'flex'}}><FontAwesomeIcon icon={faCheck} style={{fontSize: '18px', width: '3em'}} />A complete inventory management system, internal CRM system, and front end “web-shop” listing site.  With an option to turn your web-shop listings into timed on-line auctions at the press of a button.</p>

 

        <p style={{display: 'flex'}}><FontAwesomeIcon icon={faCheck} style={{fontSize: '18px', width: '4em'}} />Automated advertising of any dealer’s inventory, a one stop shop that places your listings across all major on-line marketplaces without listing each item on each portal individually.  Plus, a monthly dashboard reporting system that tells you what marketplaces give you the best value for money.</p>

 

        <p>We charge a fee per item per month, that works out to a fraction of what you will spend in house to develop these platforms.  No commissions, no set up fees. </p>



        
          
        </Col>
        </Row> 
        </Container>
        </div>

        <div>
             <FPCmiscbar2 />
             <FPCfooter />
        </div>



    </Col></Row>
    </Container>
    )

}




export default Howtobuy;
