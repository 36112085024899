import React from "react";
import {convertFromRaw, convertToRaw, Editor, EditorState, getDefaultKeyBinding, RichUtils } from "draft-js";
import "./richtexteditor.css";



class ItemPackageDets extends React.Component {
    
    constructor(props) {
      super(props);

      
      const iec = this.props.mpkgdets;
      const contentState = convertFromRaw(JSON.parse(iec));

      //console.log('Props in constructor:', props);
      //console.log('this.props.editorCont.length: ',this.props.editorCont) 

      this.state = this.props.mpkgdets?{
            editorState: EditorState.createWithContent(contentState)
        }:{
            editorState: EditorState.createEmpty()
        };
    }

    render() {
      const {editorState} = this.state;

      
      const raweditorcontent = convertToRaw(this.state.editorState.getCurrentContent());
      //console.log('EditorState: ', JSON.stringify(raweditorcontent) );

      return (
        
          
            <Editor
        
              editorState={editorState}
              readOnly
        
            />
          
        
      );
    }
  }

export default ItemPackageDets;