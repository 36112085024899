import React from 'react'



function termstxt () {

    
    
    

    return(
    
<>


<p><h6> General Terms & Conditions </h6></p>


</>
    )

}


export default termstxt;
