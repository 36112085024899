import React from 'react';
import {Modal, Button} from 'react-bootstrap';


function ThankYouModal(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="thankyou"
        centered
      >
        <Modal.Header closeButton  style={{backgroundColor: '#e9ecef'}}>
          <Modal.Title id="thankyou">
            Thank You. &nbsp;&nbsp;<span style={{fontSize: '14px'}}></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <div style={{alignItems: 'center'}}>
          <h6>Thank you for your interest.<br/><br/>
          Please check your email for verification.</h6>
        </div>
  
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default ThankYouModal;
  