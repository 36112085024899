import React, {useState, useEffect} from 'react'

import Table from 'react-bootstrap/Table'
import Countdown,  { CountdownRenderProps } from 'react-countdown';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faTrashAlt} from '@fortawesome/free-regular-svg-icons'

const remfwl = (islogged,eqpn) => {
    fetch(`https://bidlive.bid/rest/proja/api/remfwl?uid=${islogged}&enum=${eqpn}`)
    .then(res => res.json())
    .then(
    (result) => {
        // console.log('asdfasdfasdf',result)
        // document.getElementById(thefa).style.display = "none"
        // document.getElementById(theofa).style.display = "block"

    },
    (error) => {}
    )
}

function UserWatchList(props) {

    console.log(props.uid)
    const [w8lst, setw8lst] = useState([])

    useEffect(() => {
        fetch(`https://bidlive.bid/rest/proja/api/getw8lst?uid=${props.uid}`)
        .then(res => res.json())
        .then(
          (result) => {
            console.log(result)
            setw8lst(result)
          },
          (error) => {}
        )
        window.scrollTo(0, 0)
    }, [window.location.href])
    const cdrender = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {

          // Render a completed state
          //return <Completionist />;
              fetch(`https://bidlive.bid/rest/proja/api/pifinished`)
                .then(res => res.json())
                .then(
                    (result) => {
                      //console.log('asdfasdfasdf',result)
                      //document.getElementById(wlrid).style.display = "none"
                      //document.getElementById(theofa).style.display = "block"
                      console.log(result);
                    },
                    (error) => {
                      console.log(error);
                    }
                );

        }
        // Render a countdown
        return (
          <span>
            {days}d {hours}h {minutes}m {seconds}s
          </span>
        );
    };
    const remfwl = (uid,eqpn,wlrid) => {
        fetch(`https://bidlive.bid/rest/proja/api/remfwl?uid=${uid}&enum=${eqpn}`)
        .then(res => res.json())
        .then(
        (result) => {
            //console.log('asdfasdfasdf',result)
            document.getElementById(wlrid).style.display = "none"
            // document.getElementById(theofa).style.display = "block"
    
        },
        (error) => {}
        )
    }


    return (
        <div style={{backgroundColor: 'white', borderLeft: '1px solid #dedede', borderRight: '1px solid #dedede', borderBottom: '1px solid #dedede', padding: '10px'}}>
    
            

            {props.uid>0?<>

                {w8lst.length>0?<>
                <Table striped bordered size="sm" style={{fontSize: '12px', border: '0px solid #dedede'}}>
                    <thead>
                        <tr>
                          <th>LOT</th>
                          <th></th>
                          <th>Item Description/Title</th>
                          <th>Current Bid</th>
                          <th>Ends</th>
                          <th></th>
                        </tr>
                    </thead>
                    <tbody>

                    {w8lst.map( (ti,index) => (    
                        <tr id={`wlr${ti.equipnum}`}>
                            <td>
                            {(() => {
                                 // console.log(ti.HIBIDDER,' | ',islogged)
                                 //var ofimgnams_tmp=ti.imgnames.replace(/'/g, "").split(",");
                                 //console.log('imgnames:', ofimgnams_tmp);
                            })()}
                            {ti.equipnum}
                            </td>
                            <td><Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}>
                            
                                    <img src={`https://bidlive.bid/photos/olaitems/${ti.imgnames.replace(/'/g, "").split(",")[0]}?${new Date().getTime()}`} width='90px' />

                                </Link>
                            </td>
                            <td><Link to={`/forsale/${ti.equipnum}`} key={ti.equipnum}>{ti.sdesc}</Link></td>
                            <td>{ti.HIBID}</td>
                            <td><Countdown date={ti.enddate} renderer={cdrender} /></td>
                            <td><FontAwesomeIcon icon={faTrashAlt} size='lg' onClick={() => {
                                    remfwl(`${props.uid}`,`${ti.equipnum}`,`wlr${ti.equipnum}`)
                                }} />
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </Table>
                </>:<>
                    <br/><br/><br/>
                    <div style={{width: '100%', textAlign: 'center'}}>There are no items in your watchlist.</div>
                
                </>}
            </>:<>
                    <br/><br/><br/>
                    <div style={{width: '100%', textAlign: 'center'}}>Please Sign-in again.</div>
                
            </>}

            <br /><br /><br /><br/>
        </div>
    )
}

export default UserWatchList
