import React from 'react'
import Card from 'react-bootstrap/Card'
import { Button } from 'react-bootstrap'
import { Link } from "react-router-dom";

export default (props) => {
    return (

        <Card style={{ width: '22rem', margin: '15px', border: '2px', display: 'flex' }}>
          <Card.Img variant="top" src={props.imgsrc} style={{borderBottom: '4px'}} />
          <Card.ImgOverlay>
             <Link to={props.imglto}><div style={{height: '190px', width: '100px', border: '0px solid gray'}}></div></Link>        
          </Card.ImgOverlay>
            
          <Card.Body style={{backgroundColor: '#dedede', textAlign: 'center'}}>
               <Card.Title style={{color: 'black'}}>
                  {props.aucloc}
                  <br />
                  <p style={{fontSize: '12px'}}>{props.aucdats}</p>
              </Card.Title>
            
              <Card.Text>
                  {props.crdtxt}
                  {/*  <br /><br/>
                  <a href={props.lto}><span style={{fontWeight: 'bold'}}>{props.adtltxt}</span></a> */}
                  {props.addbr?<br/>:null}
              </Card.Text>
          </Card.Body>
          <Card.Footer style={{backgroundColor: '#dedede', border: '0', textAlign: 'center'}}>
            <Link to={props.lto}><Button variant="primary">View Packages</Button></Link>
          </Card.Footer>
        </Card>
    )
}