
const allcategsReducer = (state = [], action) => {

    switch(action.type){
        case 'allcategsUpdat': 
            return action.payload;
            
        default:
            return state;
    }
}
export default allcategsReducer;