import counterReducer from './counter';
import loggedReducer from './isLogged';
import categsmryReducer from './categsmry';
import allcategsReducer from './allcategs';


import { combineReducers } from 'redux';

const allReducers = combineReducers({

    counter: counterReducer,
    isLogged: loggedReducer,
    categsmry: categsmryReducer,
    
    allcategs: allcategsReducer
    

});

export default allReducers;