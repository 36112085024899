import React, {useState, useEffect, useRef} from 'react'

import {useFormik} from 'formik';
import * as Yup from 'yup';

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'
import CurrencyFormat from 'react-currency-format';

import {Button, Modal, FormText} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup'

import FormControl from 'react-bootstrap/FormControl'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGavel, faComments, faPhone, faEnvelope, faPaperPlane } from '@fortawesome/free-solid-svg-icons'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import Countdown from 'react-countdown';

import noimgcar from '../assets/img/img-placeholder.jpg';
import noimgbike from '../assets/img/img-placeholder-bike.jpg';
import noimghouse from '../assets/img/img-placeholder-house.jpg';
import noimgmisc from '../assets/img/img-placeholder-misc.jpg';
import noimggeneric from '../assets/img/img-placeholder-generic.jpg';
import noimgequip from '../assets/img/img-placeholder-equip.jpg';
import noimgatv from '../assets/img/img-placeholder-atv.jpg';

import flagthai from '../assets/img/flagThai.png';
import flagphil from '../assets/img/flagPhil.png';

import Slider from "react-slick";
import Spinner from 'react-bootstrap/Spinner'

import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import NavDummyCrumbs from './NavDummyCrumbs';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PopoverContent from 'react-bootstrap/PopoverContent'
import PopoverTitle from 'react-bootstrap/PopoverTitle'
import Popover from 'react-bootstrap/Popover'
import Overlay from 'react-bootstrap/Overlay'
//import { baseUrl } from "./config";

import ItemInquiryForm from './ModalItemInquiry.js'
import ItemInquiryTy from './ModalItemInquiryTy.js'

import ItemPackageDets from './ItemPackageDets.js';
import "../../node_modules/draft-js/dist/Draft.css";



function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


//===============================================================================================
function formatCurrency(num) {
    var num = num.toString().replace(/\$|\,/g,'');
    if(isNaN(num))
        num = "0";
    var sign = (num == (num = Math.abs(num)));
    num = Math.floor(num*100+0.50000000001);
    var cents = num%100;
    num = Math.floor(num/100).toString();
    if(cents<10)
        cents = "0" + cents;
    for (var i = 0; i < Math.floor((num.length-(1+i))/3); i++)
    num = num.substring(0,num.length-(4*i+3))+','+
    num.substring(num.length-(4*i+3));
    return (((sign)?'':'-') + '' + num + '.' + cents);
}

//=====================================================================================================================================================================================================
function Categidets ({match}) {

    

    const [eqpd, setEqpd] = useState([]);
    
    const [imgarr, setImgarr] = useState([]);
    const [ofimgnams,setofimgnams] = useState([]);
    const [errmess, setErrmess] = useState([]);
    const [tcno, setTcno] = useState(0);
    const [teno, setTeno] = useState(0);

    

    const [dontexec, setdontexec] = useState(0);

    const [modalShow, setModalShow] = useState(false);
    const [modalShowty, setModalShowty] = React.useState(false);
    

    const [show, setShow] = useState(false);
    const [strtdat, setstrtdat] = useState('');

    const [sendBtnTxt, setSendBtnTxt] = useState(" SEND ");
    const [inqSent, setInqSent] = useState(false);
  
    const handleClose = () => setShow(false);
    
    const handleCloseReload = (eqpn) => {
        setShow(false);
        window.location = `/forsale/${eqpn}`;
    }
    const handleShow = () => setShow(true);
    
    const cbidlimit = readCookie('bidlimit');
    const islogged = readCookie('islogged');

    useEffect(() => {
        fetch(`https://bidlive.bid/rest/proja/api/getidets?olaid=DAX1&equipnum=${match.params.equipnum}`)
        .then(res => res.json())
        .then(
          (result) => {
            setEqpd(result);
            
            
            for (var {consignorno: cno, equipno: eno, bidstart: bidstart} of result) {
                setTcno(cno);
                setTeno(eno);
            }

            let imgarr_ = [];
            for (let i = 0; i < 20; i++) {
                const key = "image" + i;
                const value = result[0][key];
                if (value !== "") {
                    imgarr_.push(value);
                }
            }
            setImgarr(imgarr_);

            //console.log('imgnames:', result[0].imgnames);

            var ofimgnams_tmp=result[0].imgnames.replace(/'/g, "").split(",");
            setofimgnams(ofimgnams_tmp);
            sessionStorage.setItem('aucsite', result[0].aucsiteid);
            

          },
          (error) => {setErrmess(error);console.log(error)}


        )

        

   }, [match.params.equipnum])

   
  
    

   
    const getplaceholder = ({ catid }) => {

        switch (catid) {
        case 'ATV': return noimgatv;
        case 'AUT': return noimgcar;
        case 'PUT': return noimgcar;
        case 'PRP': return noimghouse;
        case 'MIS': return noimgmisc;
        case 'MTC': return noimgbike;
        case 'HEX': return noimgequip;
        default: return noimggeneric;
        }
        
    };

    const settings = {
        customPaging: function(i) {
            return (
            // <a><img src={`https://bidlive.bid/photos/olaitems/olaia-${tcno}-${teno}_${i + 1}.jpg?${new Date().getTime()}`} /></a>
            <a><img src={`https://bidlive.bid/photos/olaitems/${ofimgnams[i]}?${new Date().getTime()}`} style={{padding: "1px"}} /></a>
            );
        },
        dots: true,
        dotsClass: "slick-dots slick-thumb",
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    const baseURL = 'https://bidlive.bid/photos/olaitems'
    
    
    //getLatestUIBStat(match.params.equipnum,'olaia')

    //console.log("currentbid:", currbid);
    //console.log("bidi:", bidi)
    

      // var pbvarcont = document.forms["pbvarcont"];
    
    // console.log('pbvarcont.minabid_ps.value',pbvarcont.minabid_ps.value);

    // useEffect(()=>{
    //     var pbvarcont = document.forms["pbvarcont"];
	//     pbvarcont.nxtminbid.value =  minAllowed;
    // })
    
        
        
    //setfordefval(fordefval=>minAllowed); (Lot No:${props.theitem}) ${props.theitemsno} - ${props.theitemdesc}
    console.log('eqpd:',eqpd[0])
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            phone: '',
            email: '',
            inq: `${eqpd.sno} - ${eqpd.shortdesc}`,
            itemdesc: '',
            itemnum: ''
        },
          
        validationSchema: Yup.object({
            firstName: Yup.string()
              //.max(25, 'Must be 25 characters or less')
              .required('Required'),
            lastName: Yup.string()
              //.max(25, 'Must be 25 characters or less')
              .required('Required'),
            phone: Yup.string()
              //.max(25, 'Must be 25 characters or less')
              .required('Required'),
            email: Yup.string()
              //.email('Invalid email address')
              .required('Required'),
            inq: Yup.string().required('Required')
        }),

        onSubmit: values => {
    
            console.log("the form content"+JSON.stringify(values, null, 2))
       
            
            fetch('https://bidlive.bid/rest/proja/api/sendinq2', {
               method: 'post',
               headers: {'Content-Type': 'application/json'},
               body: JSON.stringify(values, null, 2)
       
             }).then(res=> res.json())
               .then(res=> {setInqSent(false); setSendBtnTxt(" SEND ")}); 
            

            //props.onHide();
            //props.onNext();
   
       
        },  
    });


    return(


    <Container fluid>
        
    <Navtop /> 



      <Row style={{borderTop: '1px solid #cccccc'}}><Col sm={12} md={12} style={{maxWidth: '1140px', color: 'black', borderTop: '0px solid #cccccc'}}> 
        
      </Col></Row> 
      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black'}}>

      <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
          {eqpd.map(idet => (
          <Container key={idet.equipnum}>
                
                <Row><Col xs={12} sm={12} md={12}>
                        <NavDummyCrumbs props={idet.countryid} /> 
                </Col></Row>


              <Row>
                <Col sm={12} md={12}>
                    {/* {match.params.equipnum}    {errmess} */} &nbsp;
                </Col>

              </Row>
              <Row>
                <Col sm={12} md={6} className="d-flex justify-content-center" style={{position: 'relative'}}>

                    <Container>
                    <Row>
                    <Col>
                    

                    <div style={{position: 'relative'}}>

                    {
                        ofimgnams.length > 0
                            // IF THERE IS AN IMAGE
                            ? 
                            (
                                ofimgnams.length > 1
                                //IF MORE THAN ONE IMAGE
                                ? (
                                <div className="container" style={{width: '500px', paddingLeft: '0px', paddingRight: '0px', position: 'relative'}}>
                                    <Slider {...settings}>
                                        {ofimgnams.map(timg => (
                                            <div key={timg} style={{position: 'relative'}}>
                                                <img src={`https://bidlive.bid/photos/olaitems/${timg}?${new Date().getTime()}`} />
                                            </div>
                                        ))}
                                    </Slider>
                                </div>
                                )
                                // ONLY ONE IMAGE                
                                : (
                                    <img src={`https://bidlive.bid/photos/olaitems/${ofimgnams[0]}?${new Date().getTime()}`} />    
                                )
                            )

                            
                                // <div className="container" style={{width: '500px', paddingLeft: '0px', paddingRight: '0px'}}>
                                // {/* <img src={`https://bidlive.bid/photos/olaitems/${idet.image0}`} /> */}
                                //     <Slider {...settings}>
                                //         {imgarr.map(timg => (

                                //             <div>
                                //                 <img src={`https://bidlive.bid/photos/olaitems/${timg}`} />
                                //             </div>
                                //         ))}
                                //     </Slider>
                                // </div>


                            // IF THERE IS NO IMAGE
                            : <div style={{border: '1px solid #cccccc', width: 400, height: 300, paddingLeft: '40px', paddingTop: '40px'}}>
                                {(() => {
                                    switch (idet.catid.trim()) {
                                        case 'ATV': return (<img src={noimgatv} />);
                                        case 'AUT': return (<img src={noimgcar} />);
                                        case 'PUT': return (<img src={noimgcar} />);
                                        case 'PRP': return (<img src={noimghouse} />);
                                        case 'MIS': return (<img src={noimgmisc} />);
                                        case 'MTC': return (<img src={noimgbike} />);
                                        case 'HEX': return (<img src={noimgequip} />);
                                        default: return (<img src={noimggeneric} />)
                                    }
                                })()}
                            </div>
                    }
                    </div>
                    </Col>
                    </Row>
                    <Row>
                    <Col>

                            {/* ------------------------------------------------------------------------------------------------------------------------------------ */}


                            <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '30px'}}>

                            <Row>
                                <Col xs={12} sm={12} md={12} style={{fontSize: '14px',marginBottom: "15px", paddingTop: "6px", paddingBottom: "6px", fontWeight: "bold", backgroundColor: "#dedede"}}>PROMO DETAILS</Col>
                                
                            </Row>

                            {idet.mpkgdets?(
                            <Row>
                                <Col xs={12} sm={12} md={12}>
                                    <ItemPackageDets mpkgdets={idet.mpkgdets} />
                                </Col>

                            </Row>
                            ):null}

                            {idet.mileage!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>MILEAGE</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.mileage} {idet.mileageunit}</Col>
                            </Row>
                            ):null}
                            {idet.hours!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>HOURS</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.hours}</Col>
                            </Row>
                            ):null}
                            {idet.sno!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>VIN/SERIAL NO.</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.sno}</Col>
                            </Row>
                            ):null}
                            {idet.enginetype!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>ENGINE TYPE</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.enginetype}</Col>
                            </Row>
                            ):null}
                            {idet.horsepower!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>HORSE POWER</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.horsepower}</Col>
                            </Row>
                            ):null}
                            {(idet.year!==""||idet.year!=0)?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>YEAR</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>{idet.year}</Col>
                            </Row>
                            ):null}
                            {idet.grntlight!=="na"?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>LIGHTS</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    <span style={{backgroundColor: `${idet.grntlight}`, color: '#cccccc', borderRadius: '3px', padding: '5px'}}>{idet.grntlight}</span>
                                </Col>
                            </Row>
                            ):null}
                            {/* idet.autkeys!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>KEYS</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.autkeys}
                                </Col>
                            </Row>
                            ):null */}
                            {idet.bodystyle!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>BODY STYLE</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.bodystyle}
                                </Col>
                            </Row>
                            ):null}
                            {idet.trim!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TRIM</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.trim}
                                </Col>
                            </Row>
                            ):null}
                            {idet.colorint!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>INTERIOR COLOR</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.colorint}
                                </Col>
                            </Row>
                            ):null}
                            {idet.colorext!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>EXTERIOR COLOR</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.colorext}
                                </Col>
                            </Row>
                            ):null}
                            {idet.odostat!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>ODOMETER STATUS</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.odostat}
                                </Col>
                            </Row>
                            ):null}
                            {idet.drive!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>DRIVE</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.drive}
                                </Col>
                            </Row>
                            ):null}
                            {idet.transtype!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TRANSMISSION</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.transtype}
                                </Col>
                            </Row>
                            ):null}
                            {idet.cartitle!==""?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>TITLE</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    {idet.cartitle}
                                </Col>
                            </Row>
                            ):null}
                            {idet.caropt!=="these"?(
                            <Row>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px"}}>CAR OPTIONS:</Col>
                                <Col xs={6} sm={6} md={6} style={{fontSize: '12px', paddingBottom: "6px", fontWeight: 'bold'}}>
                                    
                                    {(() => { 
                                        var arr = idet.caropt.split(",");
                                        var arr_fer = arr.shift();
                                        return arr.toString();
                                    })()}

                                </Col>
                            </Row>
                            ):null}

                            </Container>

                            {/* ------------------------------------------------------------------------------------------------------------------------------------ */}


                    </Col>
                    </Row>
                    </Container>

                </Col>
                <Col sm={12} md={6} style={{color: 'black', marginLeft: '0px', paddingLeft: '0px'}}>

                
                    <div style={{fontSize: '18px', fontWeight: 'bold'}}>
                        {idet.shortdesc}
                    </div>
                    <div style={{fontSize: '14px', color: '#777777', fontWeight: 'bold'}}>{idet.addinfo} {idet.addinfo2?`(${idet.addinfo2})`:''}</div>
                    
                    <Container style={{fontSize: '14px', color: '#777777', paddingLeft: '0px', paddingTop: '10px'}}>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>ITEM CODE</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '18px', fontWeight: 'bold'}}>{idet.sno}</Col>
                        </Row>
                        <Row>
                            <Col xs={6} sm={6} md={3} style={{fontSize: '12px'}}>LOCATION</Col>
                            <Col xs={6} sm={6}  md={9} style={{fontSize: '14px', fontWeight: 'normal'}}>
                                {idet.locmap!==""?<>
                                    <a href={idet.locmap} target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                </>:<>
                                    {idet.itemloc}
                                </>}

                                
                                {/* 
                                {idet.countryid=="US"?<>
                                    <a href="https://goo.gl/maps/XuTfUG92EVRCxMD27" target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                </>:<>
                                    <a href="https://maps.app.goo.gl/NUhMmQkP4yREznhz6" target="_blank">{idet.itemloc}</a>&nbsp;&nbsp;
                                    
                                </>}
                                */}
                                
                                {/* {idet.countryid==='TH'?
                                    <img src={flagthai}/>
                                :
                                    <img src={flagphil}/>
                                } */}
                            </Col>
                        </Row>
                    </Container>
                        
                    
                    <div style={{borderTop: '1px solid #cccccc', padding: '0px 0px 15px 0px', marginTop: '15px', fontSize: '1em', fontWeight: 'bold'}}>
                    Inquire about this item:
                    </div>            
                    <div style={{border: '0px solid #cccccc', padding: '0px 10px 0px 10px'}}>
                    
                    <Form onSubmit={formik.handleSubmit} id="regform">
                        <Form.Row>
                            <Form.Group as={Col} controlId="cntcFname" style={{marginBottom: '0px'}}>
                            <Form.Label htmlFor="firstName" style={{fontSize: '1em', color: '#808080', marginBottom: '0px'}}>First Name {formik.touched.firstName && formik.errors.firstName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.firstName}</span>) : null}</Form.Label>
                            <Form.Control id="firstName" type="text" {...formik.getFieldProps('firstName')} size='sm'/>
                            </Form.Group>
        
                            <Form.Group as={Col} controlId="cntcLname" style={{marginBottom: '0px'}}>
                            <Form.Label htmlFor="lastName" style={{fontSize: '1em', color: '#808080', marginBottom: '0px'}}>Last Name {formik.touched.lastName && formik.errors.lastName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.lastName}</span>) : null}</Form.Label>
                            <Form.Control id="lastName" type="text" {...formik.getFieldProps('lastName')} size='sm' />
                            </Form.Group>
                        </Form.Row>
                        <Form.Row>
                            <Form.Group as={Col} controlId="cntcEmail">
                                <Form.Label htmlFor="email" style={{fontSize: '1em', color: '#808080', marginBottom: '0px'}}>Email Address {formik.touched.email && formik.errors.email ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.email}</span>) : null}</Form.Label>
                                <InputGroup size='sm'>
                                <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text></InputGroup.Prepend>
                                <Form.Control id="email" type="email" {...formik.getFieldProps('email')} size='sm' />
                                </InputGroup>
                            </Form.Group>
            
                            <Form.Group as={Col} controlId="cntcPhone">
                                <Form.Label htmlFor="phone" style={{fontSize: '1em', color: '#808080', marginBottom: '0px'}}>Phone{formik.touched.phone && formik.errors.phone ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.phone}</span>) : null} </Form.Label>
                                <InputGroup size='sm'>
                                <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text></InputGroup.Prepend>
                                <Form.Control id="phone" type="text" {...formik.getFieldProps('phone')} size='sm' />
                                </InputGroup>
                            </Form.Group>

                        </Form.Row>

                        

                        <Form.Group controlId="cntcInq">
                            
                            <Form.Label htmlFor="phone" style={{fontSize: '1em', color: '#808080', marginBottom: '0px'}}>Questions and/or comments {formik.touched.inq && formik.errors.inq ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.inq}</span>) : null} </Form.Label>
                            <InputGroup size='sm'>
                                <Form.Control id="inq" as="textarea" rows="5" {...formik.getFieldProps('inq')} size='sm' value={`Hello, I would like to receive more information about your ${idet.shortdesc} (${idet.sno}). Could you please get in contact with me?`}   />
                            </InputGroup>
                        </Form.Group>

                        <Form.Control id="itemdesc" type="text" {...formik.getFieldProps('itemdesc')} size='sm' style={{display: 'none'}} disabled />
                        

                        <Button id="inqsendbtn" variant="primary" type="submit" style={{width: '100%', marginBottom: '0px'}} disabled={inqSent}>     {sendBtnTxt} &nbsp;&nbsp; <FontAwesomeIcon icon={faPaperPlane} />    </Button>
        
                    </Form>
        


                    </div>

                    


                    <br />

                    {/* <Button size='sm' onClick={() => setModalShow(true)}><FontAwesomeIcon icon={faComments} />&nbsp;&nbsp;Inquire About This Item</Button> */}

                    <ItemInquiryForm
                        show={modalShow}
                        onHide={() => setModalShow(false)}
                        onNext={() => setModalShowty(true)}
                        theitem = {idet.equipnum}
                        theitemdesc = {idet.shortdesc}
                        theitemsno = {idet.sno}
                    />
                    <ItemInquiryTy
                        show={modalShowty}
                        onHide={() => setModalShowty(false)}
                    />
                    
                </Col>


              </Row>  
          </Container>

          ))}









          </div>
          <div>
                <br /><br /><br />
             <FPCmiscbar2 />
             <FPCfooter />

            


             
          </div>

    </Col></Row>
    </Container>
    )

}


export default Categidets;

