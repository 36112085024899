import React, {useState, useEffect} from 'react';
//import logo from './logo.svg';
// import './App.css';
import { Switch, Route, Redirect } from "react-router-dom";
import { createBrowserHistory } from "history";

import { Link } from "react-router-dom";
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'


import Navtop from './components/Navtop.js'
import Categitems from './components/Categitems.js'
import Itemdets from './components/Itemdets.js'
import Siteitems from './components/Siteitems.js'

import bllogo from './logoTransWhite.png';

import FPCparallax from './components/FPCparallax.js'
import FPCaucsites from './components/FPCaucsites.js'
import FPCmiscbar1 from './components/FPCmiscbar1.js'
import FPCmiscbar2 from './components/FPCmiscbar2.js'
import FPCfooter from './components/FPCfooter.js'
import MyVerticallyCenteredModal from './components/MyVerticallyCenteredModal.js'
import ThankYouModal from './components/ThankYouModal.js'

//import UserRegModal from './components/UserRegModal.js'


import {useDispatch } from 'react-redux';

import queryString from 'query-string';
import {FormControl, FormGroup, ControlLabel, HelpBlock, Checkbox, Radio, Button} from 'react-bootstrap';

import InputGroup from 'react-bootstrap/InputGroup'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'


import {Modal, Form} from 'react-bootstrap'
//import UserRegModal from './UserRegModal.js';

import LazyHero from 'react-lazy-hero';
import introbg from './intro-bg.jpg';


function App() {

  // let url = window.location.href;
  // let params = queryString.parse(url);
  // console.log('params', params)
  const [modalShow, setModalShow] = React.useState(false);
  const [modalShowty, setModalShowty] = React.useState(false);



  return (

    


    <div>

    <MyVerticallyCenteredModal
      show={modalShow}
      onHide={() => setModalShow(false)}
      onNext={() => setModalShowty(true)}
    />
    <ThankYouModal
      show={modalShowty}
      onHide={() => setModalShowty(false)}
    />
      
      
      <Container fluid style={{paddingTop: '0px'}}>
        
        {/* <Navtop />   */}

      
        <Row>
          <Col sm={12} md={12} style={{width: '100%', padding: '0px'}}>
          {/* 2e3957 */}
            <LazyHero imageSrc={introbg} isFixed={true} parallaxOffset={100} color='#36454F' minHeight='60vh' maxWidth='100%'>
                      <div style={{marginTop: '-100px'}}>
                      <img src={bllogo} style={{padding: '2px 10px', height: '175px', paddingBottom: '10px'}}/>
                      <br />
                      <span style={{fontSize: '36px', color: '#ffff00'}}>Club Camayan Deals</span>

                      <br />

                      <Button variant='primary' size='lg'  onClick={() => setModalShow(true)}>REGISTER HERE</Button>
                      &nbsp;&nbsp;&nbsp;&nbsp;
                      <Link to={`/login`}><Button size='lg' variant='outline-light'>Sign In&nbsp;<FontAwesomeIcon icon={faSignInAlt} /></Button></Link>
                      </div>
            </LazyHero>
            
          </Col>
        </Row>

        
        <FPCcomps />
        
        
      </Container>


      <Container>
        <Row>
          <Col md={12}>
          
          </Col>
        </Row>
      </Container>

      
     

    </div>
    
  );
}

const FPCcomps = () => (
  <>
        {/* <FPCparallax /> */}
        <FPCaucsites />
        
        <FPCmiscbar1 />
        
        <FPCfooter />
  </>
);

export default App;

// https://fontawesome.com/icons?d=gallery&q=search
