import React, {useEffect} from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';

import Categitems from './components/Categitems.js'

import Itemdets from './components/Itemdets.js'
import ItemdetsListing from './components/ItemdetsListing.js'
import ItemdetsOffer from './components/ItemdetsOffer.js'

import Siteitems from './components/Siteitems.js'
import Terms from './components/Terms.js'
import UserLogin from './components/UserLogin.js'
import UserProfile from './components/UserProfile.js'
import UserVerify from './components/UserVerify.js'
import Pagehowtobuy from './components/Pagehowtobuy.js'
import Pageotherservices from './components/Pageotherservices.js'
import Pagecontactus from './components/Pagecontactus.js'



import * as serviceWorker from './serviceWorker';

//import './assets/css/bootstrap/css/bootstrap.min.css'
import './assets/css/bootstrap/css/bootstrap.css'



import './assets/css/font-awesome/css/font-awesome.min.css'
//import './assets/css/prettyphoto/css/prettyphoto.css'
import './assets/css/hover/hoverex-all.css'
//import './assets/css/jetmenu/jetmenu.css'
//import './assets/css/owl-carousel/owl-carousel.css'

import './assets/css/_style.css'
import './assets/css/colors/blue.css'

import "./assets/css/slick/slick.css"; 
import "./assets/css/slick/slick-theme.css";

import { createBrowserHistory } from "history";


import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";

import { createStore } from 'redux';
import allReducers from './reducers';
import { Provider, useSelector, useDispatch } from 'react-redux';
import UserMyBidlive from './components/UserMyBidlive';

const store = createStore(allReducers, window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__());
const hist = createBrowserHistory();


ReactDOM.render(
      
      <Provider store={store}>
        <Router history={hist}>
        
        
          <Switch>

            <Route path="/" exact component={App} />
            <Route path="/categ/:cid" component={Categitems} />
            <Route path="/aucsite/:aucsite" exact component={Siteitems} />
            <Route path="/aucsite/:aucsite/:cid" component={Siteitems} />
            
            <Route path="/forsale/:equipnum" component={Itemdets} />
            <Route path="/itemdets/:equipnum" component={ItemdetsListing} />
            <Route path="/makeanoffer/:equipnum" component={ItemdetsOffer} />

            <Route path="/terms" component={Terms} />
            <Route path="/login" component={UserLogin} />
            <Route path="/mybidlive/:uid" component={UserMyBidlive} />
            <Route path="/howtobuy" component={Pagehowtobuy} />
            <Route path="/dealerservices" component={Pageotherservices} />
            <Route path="/contactus" component={Pagecontactus} />
            <Route path="/users/verifyemail/:evk" component={UserVerify} />


          </Switch>
        </Router>
      </Provider>
      
  ,
  document.getElementById('root')
);


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
