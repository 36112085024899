import React from 'react';

import {Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function FPCmiscbar1 () {

    return(
     <Row>
      <Col sm={12} md={12} style={{backgroundColor: '#DEE2E6', padding: '10px', marginTop: '50px'}}>
           <div style={{maxWidth: '1140px', border: '0px solid black', fontSize: '14px', color: 'gray'}} className="container-fluid justify-content-center" >
            
          </div>
      </Col>
    </Row>
    )

}
export default FPCmiscbar1;
