import React from 'react';
import {Modal, Button} from 'react-bootstrap';


function Modalcpty(props) {




    
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="thankyou"
        centered
      >
        <Modal.Header closeButton  style={{backgroundColor: '#e9ecef'}}>
          
        </Modal.Header>
        <Modal.Body>
        
        <div style={{alignItems: 'center'}}>
          
          <h6>Your password has been set.</h6>
        </div>
  
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>Close</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  export default Modalcpty;
  