import React, {useState} from 'react'

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'

import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.js'


import Navtop from './Navtop.js'


import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal, Form, Button} from 'react-bootstrap'


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEye, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link } from "react-router-dom";

import PhoneInput from 'react-phone-number-input'
import 'react-phone-number-input/style.css'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//import { baseUrl } from "./config";
import TermsTxt from './TermsTxt'


function Howtobuy () {

    const [sendBtnTxt, setSendBtnTxt] = useState(" SEND ");
    const [inqSent, setInqSent] = useState(false);

    const formik = useFormik({
        initialValues: {
          firstName: '',
          lastName: '',
          phone: '',
          email: '',
          inq: '',
          
        },
        
        validationSchema: Yup.object({
          firstName: Yup.string()
            //.max(25, 'Must be 25 characters or less')
            .required('Required'),
          lastName: Yup.string()
            //.max(25, 'Must be 25 characters or less')
            .required('Required'),
          phone: Yup.string()
            //.max(25, 'Must be 25 characters or less')
            .required('Required'),
          email: Yup.string()
            //.email('Invalid email address')
            .required('Required'),
          inq: Yup.string().required('Required')
        }),
    
        onSubmit: values => {
    
         //console.log(JSON.stringify(values, null, 2))
    
         fetch('https://bidlive.bid/rest/proja/api/sendinq', {
            method: 'post',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(values, null, 2)
    
          }).then(res=> res.json())
            .then(res=> {setInqSent(true); setSendBtnTxt("Thank you. Your inquiry has been sent.")}); 

    
        },
    
    
      });

    return(
    <Container fluid>
        
    <Navtop /> 


      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>

        <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
        <Container>
        {/*}    
        <Row><Col xs={12} sm={12} md={12}>
             <a href='http://bidlive.bid'>Home</a> &nbsp;&nbsp;/&nbsp;&nbsp;  <Link to={`/aucsite/TH1`}><a href=''>Back to List</a></Link>
            &nbsp;&nbsp;/&nbsp;&nbsp;  
        </Col></Row>
        */}

        <Row><Col xs={12} sm={12} md={6}>



                <p style={{fontWeight: 'bold', fontSize: '16px'}}>Contact Us</p>


                <Form onSubmit={formik.handleSubmit} id="regform">
                  <Form.Row>
                    <Form.Group as={Col} controlId="cntcFname">
                      <Form.Label htmlFor="firstName">First Name{formik.touched.firstName && formik.errors.firstName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.firstName}</span>) : null}</Form.Label>
                      <Form.Control id="firstName" type="text" {...formik.getFieldProps('firstName')} size='sm'/>
                      {/* <label htmlFor="firstName">First Name</label>
                      <input id="firstName" type="text" {...formik.getFieldProps('firstName')} /> */}
                      
                    </Form.Group>
  
                    <Form.Group as={Col} controlId="cntcLname">
                      <Form.Label htmlFor="lastName">Last Name{formik.touched.lastName && formik.errors.lastName ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.lastName}</span>) : null}</Form.Label>
                      <Form.Control id="lastName" type="text" {...formik.getFieldProps('lastName')} size='sm' />
                      
                      
                    </Form.Group>
                  </Form.Row>

                  <Form.Group controlId="cntcEmail">
                    <Form.Label htmlFor="email">Email Address{formik.touched.email && formik.errors.email ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.email}</span>) : null}</Form.Label>
                    <InputGroup size='sm'>
                      <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text></InputGroup.Prepend>
                      <Form.Control id="email" type="email" {...formik.getFieldProps('email')} size='sm' />
                    </InputGroup>
                  </Form.Group>
  
                  <Form.Group controlId="cntcPhone">
                    <Form.Label htmlFor="phone">Phone{formik.touched.phone && formik.errors.phone ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.phone}</span>) : null} </Form.Label>
                    <InputGroup size='sm'>
                     <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faPhone} /></InputGroup.Text></InputGroup.Prepend>
                      <Form.Control id="phone" type="text" {...formik.getFieldProps('phone')} size='sm' />
                    </InputGroup>
                  </Form.Group>

                  <Form.Group controlId="cntcInq">
                    <Form.Label htmlFor="phone">Your Inquiry{formik.touched.inq && formik.errors.inq ? (<span style={{fontSize: '11px', color: 'red'}}>{formik.errors.inq}</span>) : null} </Form.Label>
                    <InputGroup size='sm'>
                     
                      <Form.Control id="inq" as="textarea" rows="5" {...formik.getFieldProps('inq')} size='sm' />
                    </InputGroup>
                  </Form.Group>
  
                  

                  {/*
                  <Form.Group controlId="pass1">
                          <Form.Label htmlFor="pass1">New Password{formik.touched.pwordnew && formik.errors.pwordnew ? (<span style={{fontSize: '12px', color: 'red', paddingLeft: '20px'}}>{formik.errors.pwordnew}</span>) : null}</Form.Label>
                          <InputGroup size='sm'>
                              <Form.Control id="pwordnew" type={passwordShown1 ? "text" : "password"} {...formik.getFieldProps('pwordnew')} size='sm' />
                          <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity} /></InputGroup.Text></InputGroup.Append>
                          
                          </InputGroup>
                  </Form.Group>
                  <Form.Group controlId="pass2">
                          <Form.Label htmlFor="pass2">Confirm Password{formik.touched.pwordconf && formik.errors.pwordconf ? (<span style={{fontSize: '12px', color: 'red', paddingLeft: '20px'}}>{formik.errors.pwordconf}</span>) : null}</Form.Label>
                          <InputGroup size='sm'>
                              <Form.Control id="pwordconf" type={passwordShown2 ? "text" : "password"} {...formik.getFieldProps('pwordconf')} size='sm' />
                          <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity2} /></InputGroup.Text></InputGroup.Append>
                          
                          </InputGroup>
                  </Form.Group>
                  */}
  
                  <Button id="inqsendbtn" variant="primary" type="submit" style={{width: '100%', marginBottom: '0px'}} disabled={inqSent}>     {sendBtnTxt}     </Button>
  
              </Form>







        
          
        </Col>
        </Row>
        <Row><Col xs={12} sm={12} md={6}></Col></Row> 
        </Container>
        </div>

        <div>
             <FPCmiscbar2 />
             <FPCfooter />
        </div>



    </Col></Row>
    </Container>
    )

}




export default Howtobuy;
