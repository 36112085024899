import React, {useState, useEffect} from 'react'

import { useDispatch, useSelector } from 'react-redux';
import { Link } from "react-router-dom";

import Form from 'react-bootstrap/Form'
import Itemcard from './Itemcard.js'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import CurrencyFormat from 'react-currency-format';
import CardDeck from 'react-bootstrap/CardDeck'
import CardGroup from 'react-bootstrap/CardDeck'

import Card from 'react-bootstrap/Card'
import { Modal, Button, CardColumns } from 'react-bootstrap'

import Breadcrumb from 'react-bootstrap/Breadcrumb'

import noimgcar from '../assets/img/img-placeholder.jpg';
import noimgbike from '../assets/img/img-placeholder-bike.jpg';
import noimghouse from '../assets/img/img-placeholder-house.jpg';
import noimgmisc from '../assets/img/img-placeholder-misc.jpg';
import noimggeneric from '../assets/img/img-placeholder-generic.jpg';
import noimgequip from '../assets/img/img-placeholder-equip.jpg';
import noimgatv from '../assets/img/img-placeholder-atv.jpg';

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'




let andthese = [];
let rmvthese = [];

function Categitems ({match}) {
    
    const [dspcatdesc, setDspCatDesc] = useState("loading...");
    const [dspicount, setDspICount] = useState(0);
    const [allincat, setAllInCat] = useState([]);
    const [allincatOrig, setAllInCatOrig] = useState([]);
    const [allmakeic, setAllmakeic] = useState([]);
    const [allmdlic, setAllmdlic] = useState([]);
    const [checkedItems, setCheckedItems] = useState({}); //plain object as state
    const [showTheseMakes, setShowTheseMakes] = useState([]);
    const [onlyTheseMakes, setOnlyTheseMakes] = useState([]);

    

    const [yrdata, setYrData] = useState([]);
    const [prices, setPrices] = useState([]);

    const [showTheseMdls, setShowTheseMdls] = useState([]);
    var iniml = [];
    var inimdls = [];
    
    //const categsmry = useSelector(state => state.categsmry);
    //const categnam = (categsmry.find(el => el.catid == match.params.cid));
    useEffect(() => {
        fetch(`https://bidlive.bid/rest/proja/api/getaicats?olaid=DAX1&catid=${match.params.cid}`)
        .then(res => res.json())
        .then(
          (result) => {
            setDspCatDesc(result[0].catdesc);
            setDspICount(result.length);
            setAllInCat(result);
            setAllInCatOrig(result);
          },
          (error) => {}
        )

        fetch(`https://bidlive.bid/rest/proja/api/getamic?olaid=DAX1&catid=${match.params.cid}`)
        .then(res => res.json())
        .then(
          (result) => {
            setAllmakeic(result);
            result.map(tmic => (
                iniml = [...iniml,tmic.makeid]
            ))
            setShowTheseMakes(iniml);
            
          },
          (error) => {}
        )  

        fetch(`https://bidlive.bid/rest/proja/api/getamdl?olaid=DAX1&catid=${match.params.cid}`)
        .then(res => res.json())
        .then(
          (result) => {
            setAllmdlic(result);
            result.map(tmic => (
                inimdls = [...inimdls,tmic.model]
            ))
            setShowTheseMdls(inimdls);
            
          },
          (error) => {}

        )


        fetch(`https://bidlive.bid/rest/proja/api/getiyrsicat?olaid=DAX1&catid=${match.params.cid}`)
        .then((res) => res.json())
        .then(
            (result) => {
            // Parse json
            let years_arr = result.map((yrObj) => Number(yrObj.year)).sort();
            // Generate ranges then update quantities
            let ranges = updateQuantities(years_arr, generateRanges(2020));
            // Remove ranges with range.qty = 0 then setState
            setYrData(ranges.filter((range) => range.qty !== 0));
            },
            (error) => {
            console.log(error);
            }
        );

        fetch(`https://bidlive.bid/rest/proja/api/getcurrpricescat?olaid=DAX1&catid=${match.params.cid}`)
        .then((res) => res.json())
        .then(
          (result) => {
            // Parse json
            const sorted = result
              .map((prices) => Number(prices.bidstart))
              .sort((a, b) => a - b);
            // Count occurrences
            let counts = {};
            for (var i = 0; i < sorted.length; i++) {
              var num = sorted[i];
              counts[num] = counts[num] ? counts[num] + 1 : 1;
            }
            // Create Ranges
            let ranges = createRanges(
              Object.keys(counts).map((key) => Number(key)),
              10
            );
            //  Update quantities
            ranges = updateQuantitiesPrices(counts, ranges).filter(
              (range) => range.qty !== 0
            );
            setPrices(ranges);
            console.log(ranges);
          },
          (error) => {
            console.log(error);
          }
    );


   }, [match.params.cid])



   //===========================================================================================================================================
     /*
  generate possible date ranges [ { min: 2019, max: 2020, qty: 0}, ]
  */
 const generateRanges = (currentYear) => {
    let output = [];
    let start = currentYear - 1;
    output.push({ min: currentYear - 1, max: currentYear, qty: 0 });
    for (let i = 0, ctr = 0, gap = 2; i < 10; i++, ctr++) {
      if (ctr === 2) {
        ctr = 0;
        gap++;
      }
      if (i > 7) gap = 10;
      output.push({ min: start - gap, max: start - gap + (gap - 1), qty: 0 });
      if (i === 9) output.push({ min: 0, max: output[i + 1].min - 1, qty: 0 });
      start = start - gap;
    }
    return output;
  };

  //PRICES
  const createRanges = (prices, numOfRanges) => {
    let span = 0;
    let excess = 0;
    let output = [];
    let min = 0;
    let max = 0;
    excess = prices.length % numOfRanges;
    if (prices.length / numOfRanges >= 2) {
      span = parseInt(prices.length / numOfRanges);
    } else {
      span = 2;
    }
    for (let i = 0, ctr = 0; i < prices.length; i++) {
      if (ctr === 0) min = prices[i];
      if (ctr === span - 1) {
        max = prices[i];
        output.push({ min: min, max: max, qty: 0 });
        ctr = 0;
      } else {
        ctr++;
      }
    }
    if (excess === 1) {
      output.push({
        min: prices[prices.length - 1],
        max: prices[prices.length - 1],
        qty: 0,
      });
    } else if (excess > 1) {
      output.push({
        min: prices[prices.length - excess],
        max: prices[prices.length - 1],
        qty: 0,
      });
    }
    return output;
  };

  /*
  Updates values of ranges.qty according to contents of yearArr. 
  where:
    yearArr - array of years in int data type |    ex: [ 2001, 2002 ]
    ranges - array of range objects |    ex: [ {min: 1995, max: 2020, qty: 0 } ]
  */
  const updateQuantities = (yearArr, ranges) => {
    let output = Object.assign([], ranges);
    yearArr.forEach((year) => {
      let idx = output.length - 1;
      while (true) {
        if (idx < 0) break;
        // year within range ? push then proceed to next year : check next range
        if (year >= output[idx].min && year <= output[idx].max) {
          output[idx].qty = output[idx].qty + 1;
          break;
        }
        idx--;
      }
    });
    return output;
  };

  //PRICES
  const updateQuantitiesPrices = (counts, ranges) => {
    let output = Object.assign([], ranges);
    const price = Object.keys(counts).map((key) => Number(key));
    const quantity = Object.values(counts).map((key) => Number(key));
    for (let i = 0; i < price.length; i++) {
      for (let j = 0; j < output.length; j++) {
        if (price[i] >= output[j].min && price[i] <= output[j].max) {
          output[j].qty += quantity[i];
          break;
        }
      }
    }
    return output;
  };
  //=====================================================================================================================================================


   switch (match.params.cid) {
        case 'ATV': {var noimg = noimgatv;break;}
        case 'AUT': {var noimg = noimgcar;break;}
        case 'PUT': {var noimg = noimgcar;break;}
        case 'PRP': {var noimg = noimghouse;break;}
        case 'MIS': {var noimg = noimgmisc;break;}
        case 'MTC': {var noimg = noimgbike;break;}
        case 'HEX': {var noimg = noimgequip;break;}
        default: {var noimg = noimggeneric}
    }
    
    var cursyment = "36"

    
    //AVAILABLE MAKES FILTER ALGORITHM
    const handleChecked = event => {
        if (event.target.checked) {
            console.log('remove all leave only those checked');
            for (let i = 0; i < allincatOrig.length; i++) {
                if (allincatOrig[i].makeid === event.target.id) {
                    andthese.unshift(allincatOrig[i]);
                }
            }
            setAllInCat(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           console.log('put back', event.target.id);
           for (let i = 0; i < allincat.length; i++) {
                if (allincat[i].makeid === event.target.id) {
                    allincat.splice(i,1); i--;
                }
            }
            console.log(allincat);
            setAllInCat(allincat => allincat);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allincat))
        }
    }

    
    //AVAILABLE MODELS FILTER ALGORITHM
    const handleMdlChecked = event => {
        if (event.target.checked) {
            console.log('remove all leave only those checked');
            for (let i = 0; i < allincatOrig.length; i++) {
                if (allincatOrig[i].model === event.target.id) {
                    andthese.unshift(allincatOrig[i]);
                }
            }
            setAllInCat(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           console.log('put back', event.target.id);
           for (let i = 0; i < allincat.length; i++) {
                if (allincat[i].model === event.target.id) {
                    allincat.splice(i,1); i--;
                }
            }
            console.log(allincat);
            setAllInCat(allincat => allincat);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allincat))
        }


    }

    const handleYrChecked = event => {
        let yrrange = event.target.id.split("-",2);
        console.log(yrrange[0])
        console.log(yrrange[1])
        if (event.target.checked) {
            console.log('remove all leave only those checked');
            for (let i = 0; i < allincatOrig.length; i++) {
                if (allincatOrig[i].year >= yrrange[0] && allincatOrig[i].year <= yrrange[1]) {
                    andthese.unshift(allincatOrig[i]);
                }
            }
            setAllInCat(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           console.log('put back', event.target.id);
           for (let i = 0; i < allincat.length; i++) {
                if (allincatOrig[i].year >= yrrange[0] && allincatOrig[i].year <= yrrange[1]) {
                    allincat.splice(i,1); i--;
                }
            }
            console.log(allincat);
            setAllInCat(allincat => allincat);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allincat))
        }
        //console.log();
    }

    const handlePriceChecked = event => {
        let prcrange = event.target.id.split("-",2);
        console.log(prcrange[0])
        console.log(prcrange[1])
        if (event.target.checked) {
            console.log('remove all leave only those checked');
            for (let i = 0; i < allincatOrig.length; i++) {
                if (allincatOrig[i].bidstart >= prcrange[0] && allincatOrig[i].bidstart <= prcrange[1]) {
                    andthese.unshift(allincatOrig[i]);
                }
            }
            setAllInCat(andthese);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(andthese))
        }else{
           console.log('put back', event.target.id);
           for (let i = 0; i < allincat.length; i++) {
                if (allincatOrig[i].bidstart >= prcrange[0] && allincatOrig[i].bidstart <= prcrange[1]) {
                    allincat.splice(i,1); i--;
                }
            }
            console.log(allincat);
            setAllInCat(allincat => allincat);
            setOnlyTheseMakes(onlyTheseMakes => onlyTheseMakes.concat(allincat))
        }
        //console.log();
    }





   return(
        <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black', borderTop: '1px solid #cccccc'}}>

           <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
           
            <Container>
                <Row>
                    <Col sm={12} md={12} style={{fontSize: "14px", color: '#2e3957'}} >
                    {/* <Breadcrumb style={{marginTop: "0px"}}>
                        <Breadcrumb.Item  style={{fontSize: "14px"}}>Home</Breadcrumb.Item>
                        <Breadcrumb.Item style={{fontSize: "14px"}} active>
                            {dspcatdesc}
                        </Breadcrumb.Item>
                    </Breadcrumb> */}
                     {/* <Link to='/devdemo'>Home</Link> / {dspcatdesc} */} <br/>

                    </Col>
                </Row>
                <Row>
                <Col sm={12} md={3} style={{color: '#777777'}}>
                    <h6>Refine Results</h6>
                </Col>
                <Col sm={12} md={9} style={{color: '#555555'}}>
                <h5>{dspcatdesc} {}</h5>
                </Col>
                </Row>
                <Row>
                    <Col sm={12} md={3} style={{border: '0px solid black'}}>

                    {/* AVAILABLE MODELS TEMPORARY TO SUBCATEGORIES, MOVED UP ONE SECTION*/}
                    <div style={{border: '1px solid #cccccc'}} >
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Item Sub-categories</h6>
                        </div>

                        <Form.Group controlId="formBasicCheckboxMdl" style={{fontSize: '14px', paddingLeft: '10px'}}>
                        {allmdlic.map(tmic => (
                            <Form.Check 
                                type="checkbox" 
                                label={`${tmic.model} (${tmic.ilan})`} 
                                id={tmic.model}
                                key={tmic.model}
                                name={tmic.model}
                                onClick={handleMdlChecked}
                            />
                        ))}
                        </Form.Group> 

                    </div>  
                    
                    <div style={{border: '1px solid #cccccc'}} >
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Available Makes</h6>
                        </div>

                        <Form.Group controlId="formBasicCheckbox" style={{fontSize: '14px', paddingLeft: '10px'}}>
                        {allmakeic.map(tmic => (
                            <Form.Check 
                                type="checkbox" 
                                label={`${tmic.makedesc} (${tmic.ilan})`} 
                                id={tmic.makeid}
                                key={tmic.makeid}
                                name={tmic.makeid}
                                onClick={handleChecked}
                            />
                        ))}
                        </Form.Group> 

                    </div>
                    
                    {/* <div style={{border: '1px solid #cccccc'}} >
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Year</h6>
                        </div>
                        <Form.Group controlId="formBasicCheckbox" style={{ fontSize: "14px", paddingLeft: "10px" }}>
                            {yrdata.map((range, idx) => (
                            

                            
                            <Form.Check
                                type="checkbox"
                                key={idx}
                                id={`${range.min}-${range.max}`}
                                label={
                                range.min === 0
                                    ? "Below " + (range.max + 1) + " (" + range.qty + ")"
                                    : range.min + "-" + range.max + " (" + range.qty + ")"
                                }
                                onClick={handleYrChecked} // range = { min: ####, max: ####, qty: # }
                            />
                            ))}
                        </Form.Group>
                        

                              </div> */}
                    <div style={{border: '1px solid #cccccc'}} >
                        <div style={{color: "#555555", backgroundColor: '#e9ecef', padding: "5px"}}>
                            <h6>Price</h6>
                        </div>
                        <Form.Group
                            controlId="formBasicCheckbox"
                            style={{ fontSize: "14px", paddingLeft: "10px" }}
                        >
                            {prices.map((price, idx) => (
                            <Form.Check
                                type="checkbox"
                                key={idx}
                                id={`${price.min}-${price.max}`}
                                label={
                                price.max === price.min
                                    ? price.min + " and above " + "(" + price.qty + ")"
                                    : price.min + " - " + price.max + " (" + price.qty + ")"
                                }
                                onClick={handlePriceChecked} // price = { min: ####, max: ####, qty: # }
                            />
                            ))}
                        </Form.Group>

                    </div>
                    

                    </Col>
                    <Col sm={12} md={9} style={{border: '0px solid #CCCCCC'}}>
                        
                        {/* <div className="d-flex d-fill"> */}


                        <div className="container-fluid content-row">
                            <div className="row">
                                {allincat.map( (ti,index) => (        
                                <div className="col-sm-12 col-lg-4" style={{padding: '5px', marginRight: '0px', marginBottom: '0px'}} key={ti.equipnum}>
                                    <div className="card h-100" style={{maxWidth: '24rem'}}>
                                        <Itemcard payload={[{...ti},noimg=`${noimg}`]}/>
                                        
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    
                    </Col>

                </Row>

            </Container>

           </div>


           <div>
                                    
                <FPCmiscbar2 />
                <FPCfooter />

                

                

           </div>
           

        </Col></Row>
        
        
        
        
        
        
    )

}
export default Categitems;

//#D79922 <-- GOLD