import React, {useState} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';
import {Modal, Form, Button} from 'react-bootstrap'
import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import InputGroup from 'react-bootstrap/InputGroup'
import { Link } from "react-router-dom";
import { faEye } from "@fortawesome/free-solid-svg-icons";



function CreatePassw(props) {

    const [cpnogo, setcpnogo] = useState(true);
    const [cpnogomsg, setcpnogomsg] = useState('');
    const [passwordShown, setPasswordShown] = useState(false);
    
    const togglePasswordVisiblity = () => {
        console.log('toggle eye')
        setPasswordShown(passwordShown ? false : true);

    };

  
    const formik = useFormik({
      initialValues: {
        email: '',
      },
      
      validationSchema: Yup.object({
        email: Yup.string()
          .email('Invalid email address')
          .required('Required'),
      }),
  
      onSubmit: values => {
  
        //console.log(JSON.stringify(values, null, 2))
  

        fetch('https://bidlive.bid/rest/proja/api/savenewpw', {
           method: 'post',
           headers: {'Content-Type': 'application/json'},
           body: JSON.stringify(values, null, 2)
         }).then(res=> res.json())
           .then(res => console.log(res));
        
  
        props.onHide();
        props.onNext();
        //setModalShowty(true);
  
  
      },
  
  
    });
  
    function closeThisModal() {
      
  
    }
    function findEmail(props) {
      console.log(props.value);

      fetch(`https://bidlive.bid/rest/proja/api/userfindemail?email=${props.value}`)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.length !== 0){
                
                for (var {name: nam, pword: oldpw} of result) {
                    
                    if (oldpw !== "") {
                        setcpnogomsg(cpnogo => `You already have a password saved.`); 
                        setcpnogo(cpnogo => true);
                    }else{
                        setcpnogomsg(cpnogo => `Hello ${nam}. You may create your password.`);
                        setcpnogo(cpnogo => false);
                    }
                }
            }else{
                setcpnogo(cpnogo => true);
                setcpnogomsg(cpnogo => 'The email was not found. Please Register.');
            }
          },
          (error) => {}
        )
    }
  
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton  style={{backgroundColor: '#e9ecef'}}>
          <Modal.Title id="contained-modal-title-vcenter">
            Create a password &nbsp;&nbsp;<span style={{fontSize: '14px'}}></span>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
        
        <Container>
          <Row>
          <Col sm={6} md={2}></Col>
            <Col sm={6} md={8}>
  
              <Form onSubmit={formik.handleSubmit} id="regform">
  
                  <Form.Group controlId="email">
                    <Form.Label htmlFor="email">Please enter the email you registered with:</Form.Label>
                    <InputGroup size='sm'>
                      
                      <Form.Control id="email" type="email" {...formik.getFieldProps('email')} onBlur={() => findEmail(formik.getFieldProps('email'))} size='sm' />
                      <InputGroup.Prepend><InputGroup.Text><FontAwesomeIcon icon={faEnvelope} /></InputGroup.Text></InputGroup.Prepend>
                    </InputGroup>
                    {formik.touched.email && formik.errors.email ? (<div style={{fontSize: '11px', color: 'red'}}>{formik.errors.email}</div>) : null}
                  </Form.Group>
                  
                        <span style={{color: 'red'}}>{cpnogomsg}</span>
                    <Form.Group controlId="phone">
                        <Form.Label htmlFor="phone">New Password</Form.Label>
                        <InputGroup size='sm'>
                            <Form.Control id="pwordnew" type={passwordShown ? "text" : "password"} {...formik.getFieldProps('pwordnew')} size='sm' disabled={cpnogo} />
                        <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity} /></InputGroup.Text></InputGroup.Append>
                        {/* {formik.touched.phone && formik.errors.phone ? (<div style={{fontSize: '11px', color: 'red'}}>{formik.errors.phone}</div>) : null}   */}
                        </InputGroup>
                    </Form.Group>
  
                  <Button variant="primary" type="submit" style={{width: '100%', marginBottom: '0px'}} disabled={cpnogo}>Set my password</Button>
  
              </Form>

              <span style={{fontSize: '12px'}}>By becoming a member you agree to our <Link to={`/terms`}>Terms & Conditions.</Link> </span>
  
            </Col>
  
  
            <Col sm={6} md={2}>
            </Col>
          </Row>
        </Container>
  
        </Modal.Body>
        {/* <Modal.Footer>
          
        </Modal.Footer> */}
      </Modal>
    );
  }

  export default CreatePassw;