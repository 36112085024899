export const increment = (nr) => {
    return {
        type: 'INCREMENT',
        payload: nr
    }
};
export const decrement = () => {
    return {
        type: 'DECREMENT'
    }
};
export const categsmryUpdat = (cats) => {
    return {
        type: 'categsmryUpdat',
        payload: cats
    }
};
export const allcategsUpdat = (cats) => {
    return {
        type: 'allcategsUpdat',
        payload: cats
    }

};
export const isLoggedUpdat = (usrinf) => {
    return {
        type: 'isLoggedUpdat',
        payload: usrinf
    }
};

export const userinfoUpdat = (usrinf) => {
    return {
        type: 'userinfoUpdat',
        payload: usrinf
    }
};
