
import React from 'react'

import Navtop from './Navtop.js'
import NavDummyCrumbs from './NavDummyCrumbs'
import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faHeart, faGavel, faShoppingCart} from '@fortawesome/free-solid-svg-icons'

import Tabs from 'react-bootstrap/Tabs'
import Tab from 'react-bootstrap/Tab'

import UserWatchList from './mybidlive/UserWatchList'
import UserActiveItems from './mybidlive/UserActiveItems'
import UserPurchases from './mybidlive/UserPurchases'






function readCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}


function UserMyBidlive({match}) {


    const cunam = readCookie('uname');
    const islogged = readCookie('islogged');
    return (
        <Container fluid>
        
        <Navtop /> 
    
    
          <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>
    
            <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
            <Container>
                
            <Row><Col xs={12} sm={12} md={12}>
                <NavDummyCrumbs />
            </Col></Row>

            <Row>
                <Col xs={12} sm={12} md={12}>
                
                <br/>
                <Container style={{backgroundColor: '#f8f9fa', border: '1px solid #dee2e6'}}>   
                  <Row>
                  <Col xs={12} sm={12} md={2} style={{border: '0px solid #dedede', padding: '10px'}}>
                            
                            <div style={{fontSize: '12px', fontWeight: 'bold'}}>{cunam}</div><br/><br/>
                            
                            My Profile

                  </Col>
                  <Col xs={12} sm={12} md={10} style={{border: '0px solid #dedede', padding: '10px'}}>

                    {/* =============================================================================================================== */}
                    <Tabs defaultActiveKey="active" transition={false} id="mybidlive-tabs">
                        
                        
                        <Tab eventKey="watch" title={<span><FontAwesomeIcon icon={faHeart} />&nbsp;&nbsp;Watchlist</span>}>
                            <UserWatchList uid={islogged} />
                        </Tab>
                        <Tab eventKey="active" title={<span><FontAwesomeIcon icon={faGavel} />&nbsp;&nbsp;Active Items</span>}>
                            <UserActiveItems uid={islogged} />
                        </Tab>  
                        <Tab eventKey="purchases" title={<span><FontAwesomeIcon icon={faShoppingCart} />&nbsp;&nbsp;Purchases</span>}>
                            <UserPurchases uid={islogged} />
                        </Tab>
                    </Tabs>
                    {/* =============================================================================================================== */}
   
                  </Col>
                  </Row>
                </Container>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={12} md={12}>
                <br/><br/><br/>
              
                </Col>
            </Row> 
            </Container>
            </div>
    
            <div>
                 <FPCmiscbar2 />
                 <FPCfooter />
            </div>
    
    
    
        </Col></Row>
        </Container>
    )
}


export default UserMyBidlive
