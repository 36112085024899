import React from 'react';

import Fpcardset1 from '../Fpcardset1.js'
import Fpcards1i1 from '../assets/img/pafeat1.jpg'
import Fpcards1i2 from '../assets/img/pafeat2.jpg'
import Fpcards1i3 from '../assets/img/pafeat3.jpg'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import {Button} from 'react-bootstrap';
import Container from 'react-bootstrap/Container'



function FPCfooter () {
    return (
    <Row>
      <Col sm={12} md={12}>
          <Container  className="container-fluid content-row" >
             <Row style={{justifyContent: 'center', marginTop: '-75px'}}>
              
             {/* <div style={{display: 'flex'}}> */}
              <Col sm={12} md={4}>
                <Fpcardset1 imgsrc={Fpcards1i3} crdtxt='Make a Splash with Nonstop Thrills: Dive into the Ultimate All-In Adventure!' aucloc='ALL IN PACKAGE!' aucdats='' lto='/aucsite/ID1' imglto='/makeanoffer/2758' adtltxt='Make An Offer or Buy It Now' />
              </Col>
              <Col sm={12} md={4}>
                <Fpcardset1 imgsrc={Fpcards1i2} crdtxt='One Pass, Endless Adventures – Get Super Pass Today!' aucloc='SUPER PASS' aucdats=''  lto='aucsite/ID1' imglto='/makeanoffer/2740' adtltxt='Make An Offer or Buy It Now'/>
              </Col>
              <Col sm={12} md={4}>
                <Fpcardset1 imgsrc={Fpcards1i1} crdtxt='Swim with Wonder: Discover the Magic at Ocean Adventure Park!' aucloc='Ultimate Barkada Goals' aucdats=''  lto='aucsite/ID1' imglto='/makeanoffer/2757' adtltxt='Make An Offer or Buy It Now' />
              </Col>
      
              {/* </div> */}
              <br /><br />
              
            </Row>   
          </Container>
      </Col>
    </Row>

    )

};
export default FPCfooter;
