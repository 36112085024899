import React from 'react';

import {Button} from 'react-bootstrap';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

function FPCmiscbar1 () {

    return(
     <Row>
      <Col sm={12} md={12} style={{padding: '10px', marginTop: '50px'}}>
           <div style={{maxWidth: '1140px', border: '0px solid black', fontSize: '14px', color: 'gray'}} className="container-fluid justify-content-center" >
                
                
           {/* <span style={{color: 'black'}}>IMPORTANT NOTICE:</span> We are testing a new bidding system and user session interface during the on-line bidding on items that are live now. Rest assured that if there are any glitches or bugs that affect bidding, the closing date and time for items will be extended while we work out any errors. Please bear with us for the next few days and help us test the bidding system.  */}
           

            <br /><br />


          </div>
      </Col>
    </Row>
    )

}
export default FPCmiscbar1;
