import React, {useState, useEffect} from 'react';
import {useFormik} from 'formik';
import * as Yup from 'yup';

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'


import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

import TermsTxt from './TermsTxt'

//import { baseUrl } from "./config";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faExclamationTriangle, faTools } from '@fortawesome/free-solid-svg-icons'
import { faSignInAlt } from '@fortawesome/free-solid-svg-icons'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { faEnvelope} from '@fortawesome/free-solid-svg-icons'
import { faEye } from "@fortawesome/free-solid-svg-icons";

import MyVerticallyCenteredModal from './MyVerticallyCenteredModal.js'
import ThankYouModal from './ThankYouModal.js'

import NavDummyCrumbs from './NavDummyCrumbs'

var tuve_ns = 0


function UserVerify ({match}) {

    const [modalShow, setModalShow] = React.useState(false);
    const [modalShowty, setModalShowty] = useState(false);
    const [passwordShown1, setPasswordShown1] = useState(false);
    const [passwordShown2, setPasswordShown2] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [showNVMsg, setShowNVMsg] = useState(false);
    const [userDets, setUserDets] = useState([]);
    const [npasnogo, setnpasnogo] = useState(true);
    const [showform, setshowform] = useState(true);
    const [showgoodtogo, setshowgoodtogo] = useState(false);
    const [tuve, settuve] = useState(0);

    const togglenpasnogo = () => {
        //console.log('toggle eye')
        setnpasnogo(npasnogo ? false : true);
        
    };
    const hidepassform = () => {
        //console.log('toggle eye')
        setshowform(false);
        
    };


    useEffect(() => {
        fetch(`https://bidlive.bid/rest/proja/api/verifyEmail?evk=${match.params.evk}`)
        .then(res => res.json())
        .then(
          (result) => {
            if (result.length){
                setEmailVerified(true);
                console.log(result)
                setUserDets(result);
                {result.map( (tu,index) => (
                    settuve(tu.userid)
                ))}
                if (result[0].pword !== '') {
                    setshowform(false);
                }
                
              
                //window.location.reload();
            }else{
                setShowNVMsg(true);
            }
            
            

          },
          (error) => {}


        )
    }, [match.params.evk])


    const togglePasswordVisiblity = () => {
        console.log('toggle eye')
        setPasswordShown1(passwordShown1 ? false : true);

    };
    const togglePasswordVisiblity2 = () => {
        console.log('toggle eye')
        setPasswordShown2(passwordShown2 ? false : true);

    };

    var tuve_ns = tuve
    console.log('tuve',tuve)

    const formik = useFormik({
        initialValues: {
          pwordnew: '',
          pwordconf: '',
          uid: tuve_ns,
          
        },
        
        validationSchema: Yup.object({
            pwordnew: Yup.string().required('Password is required'),
            pwordconf: Yup.string()
               .oneOf([Yup.ref('pwordnew'), null], 'Passwords must match')
          }),
    
        onSubmit: values => {

          //console.log(values);
            values.uid = tuve
          //console.log({...values})
            // [{...ti},noimg=`${noimg}`]

    
            console.log(JSON.stringify(values, null, 2))
    
            fetch('https://bidlive.bid/rest/proja/api/savenewpass', {
             method: 'post',
             headers: {'Content-Type': 'application/json'},
             body: JSON.stringify(values, null, 2)
               }).then(res=> res.json())
             .then(res => console.log(res));

             setshowform(false);
             setshowgoodtogo(true);


           
    
          //props.onHide();
          //props.onNext();
          //setModalShowty(true);
    
    
        },
    
    
      });
    
      function closeThisModal() {
        
    
      }
      






    return (

    <Container fluid>
    <Navtop /> 


      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>

        <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
        <Container>
            
        <Row><Col xs={12} sm={12} md={12}>
            <NavDummyCrumbs />
        </Col></Row>

        <Row><Col xs={12} sm={12} md={12}>
            <br/>
            <Container style={{backgroundColor: 'white', border: '1px solid #dee2e6'}}>
                <Row>
                    <Col xs={12} sm={12} md={6} style={{border: '0px solid #dedede', paddingTop: '30px', paddingLeft: '30px'}}>
                        
                        {emailVerified?<>
                            {userDets.map( (tu,index) => (
                               <>
                                
                                <span style={{color: '#284B63', fontWeight: 'bold'}}>Great! Welcome back {tu.fname}!</span> <br /><br />
                                <span style={{color: '#284B63', fontWeight: 'bold'}}>Your email ({tu.email}) is now verified.</span> <br /><br />
                                
                                {showform?<>
                                    <span style={{color: '#284B63'}}><strong>IMPORTANT:</strong> You don't have a password yet. Before starting to use Bidlive, you need to set your password.</span> <br /><br />
                                    <span style={{color: '#284B63'}}>Please read and agree to the Terms and Conditions to be able to set your new password.</span>
                                </>:null}
                                
                                <br /><br />
                               

                               </> 
                            ))}
                        </>:<>
                            {showNVMsg?<><span style={{color: 'red', fontWeight: 'bold'}}>Sorry, the email verification key did not match.</span> <br /><br /></>:null}
                        </>}

                    </Col>

                    {/* =============================================================================================================================================================== */}
                    
                    
                    <Col xs={12} sm={12} md={6} style={{borderLeft: '0px solid #dedede', paddingRight: '30px', paddingTop: '30px'}}>

                        {emailVerified && showform?<>
                           
                            
                            <Form onSubmit={formik.handleSubmit} id="npForm">

                                <Form.Group controlId="pass1">
                                        <Form.Label htmlFor="pass1">New Password{formik.touched.pwordnew && formik.errors.pwordnew ? (<span style={{fontSize: '12px', color: 'red', paddingLeft: '20px'}}>{formik.errors.pwordnew}</span>) : null}</Form.Label>
                                        <InputGroup size='sm'>
                                            <Form.Control id="pwordnew" type={passwordShown1 ? "text" : "password"} {...formik.getFieldProps('pwordnew')} size='sm' />
                                        <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity} /></InputGroup.Text></InputGroup.Append>
                                        
                                        </InputGroup>
                                </Form.Group>
                                <Form.Group controlId="pass2">
                                        <Form.Label htmlFor="pass2">Confirm Password{formik.touched.pwordconf && formik.errors.pwordconf ? (<span style={{fontSize: '12px', color: 'red', paddingLeft: '20px'}}>{formik.errors.pwordconf}</span>) : null}</Form.Label>
                                        <InputGroup size='sm'>
                                            <Form.Control id="pwordconf" type={passwordShown2 ? "text" : "password"} {...formik.getFieldProps('pwordconf')} size='sm' />
                                        <InputGroup.Append><InputGroup.Text><FontAwesomeIcon icon={faEye} onClick={togglePasswordVisiblity2} /></InputGroup.Text></InputGroup.Append>
                                        
                                        </InputGroup>
                                </Form.Group>
                                
                                <Button variant="primary" type="submit" style={{width: '50%', marginBottom: '0px'}} disabled={npasnogo}>Set my password</Button>
                                <span style={{fontSize: '11px', color: 'gray'}}>&nbsp;&nbsp; Agree to terms to enable button </span>

                            </Form>
                            
                            

                        </>:<> {showgoodtogo?<>
                                <span style={{color: '#284B63', fontWeight: 'bold', color: 'green'}}>Success!</span> <br /><br />
                                <span style={{color: '#284B63', color: 'green'}}>You may now login with your registered email and your new password.</span><br /><br />
                                <Link to={`/login`}><Button size='sm' style={{width: '50%', marginBottom: '0px'}}>Click here to Sign In now&nbsp;<FontAwesomeIcon icon={faSignInAlt} /></Button></Link>
                             </>:null}
                        </>}
                    
                    </Col>

                </Row>
                <Row>
                    <Col xs={12} sm={12} md={12} style={{paddingLeft: '40px', paddingRight: '40px', backgroundColor: '#F8F9FA', borderTop: '1px solid #dedede'}}>
                        <br /><TermsTxt /><br />
                        <Form.Group controlId="formBasicCheckbox">
                            <Form.Check variant="lg" type="checkbox" label="I UNDERSTAND AND AGREE TO THE TERMS AND CONDITIONS" onClick={togglenpasnogo} />
                        </Form.Group>
                        <span onClick={() => window.scrollTo(0, 0)} style={{cursor: 'pointer', color: 'blue'}}>Back to Top</span>

                    </Col>
                </Row>

            </Container>

        </Col></Row>





        <Row><Col xs={12} sm={12} md={12}>
            <br/><br/><br/>
          
        </Col>
        </Row> 
        </Container>
        </div>

        <div>
             <FPCmiscbar2 />
             <FPCfooter />
        </div>



    </Col></Row>
    </Container>
    )

}




export default UserVerify;
