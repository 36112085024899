import React from 'react'

import FPCmiscbar2 from './FPCmiscbar2.js'
import FPCfooter from './FPCfooter.js'


import {Button} from 'react-bootstrap';
import Form from 'react-bootstrap/Form'

import Navtop from './Navtop.js'
import { Link } from "react-router-dom";

import InputGroup from 'react-bootstrap/InputGroup'
import FormControl from 'react-bootstrap/FormControl'

import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

//import { baseUrl } from "./config";
import TermsTxt from './TermsTxt'


function terms () {


    return(
    <Container fluid>
        
    <Navtop /> 


      <Row><Col sm={12} md={12} style={{padding: '0px', color: 'black',borderTop: '1px solid #cccccc'}}>

        <div style={{maxWidth: '1140px', border: '0px solid black'}} className="container-fluid d-flex justify-content-center" >
        <Container>
            {/*
        <Row><Col xs={12} sm={12} md={12}>
            <a href='http://bidlive.bid'>Home</a> &nbsp;&nbsp;/&nbsp;&nbsp;  <Link to={`/aucsite/TH1`}><a href=''>Back to List</a></Link>
            &nbsp;&nbsp;/&nbsp;&nbsp;  
            </Col></Row> */}





        <Row><Col xs={12} sm={12} md={12}>
          <TermsTxt />
        </Col>
        </Row> 
        </Container>
        </div>

        <div>
             <FPCmiscbar2 />
             <FPCfooter />
        </div>



    </Col></Row>
    </Container>
    )

}




export default terms;
